@import './variables.scss';

.boxMessage {
    padding         : 1rem 2rem 2rem 2rem;
    border          : solid $light3 0.01rem;
    width           : 75%;
    min-height      : 15%;
    background-color: $white;
    margin-top      : 0.5rem;
    word-wrap       : break-word;
    overflow        : hidden;
    text-overflow   : ellipsis;

    .row {
        @extend %row;
        justify-content: flex-start;
        justify-items  : flex-start;
        align-items    : center;
        align-content  : center;
    }

    h2 {
        margin-left: 0.4rem;
        color      : $blue;
        font-size  : 1.4rem;
    }

    p {
        font-size : 1.3rem;
        margin-top: 1rem;
        text-align: justify;
    }
}

.boxEvent {
    display       : flex;
    flex-direction: column;
    width         : 75%;
    min-height    : 10rem;
    margin-top    : 0.5rem;
    border-radius : .1rem .1rem .1rem .1rem;
    height        : auto;

    img {
        position     : relative;
        width        : 100%;
        height       : 10rem;
        border-radius: 0.1rem 0.1rem 0 0;
    }

    .header {
        background-color: $grayBlue;
        width           : 100%;
        height          : 3rem;
        border-radius   : 0.3rem 0.3rem 0rem 0rem;
        color           : $white;
        display         : flex;
        justify-content : flex-start;
        align-items     : center;
        justify-items   : flex-start;
        align-content   : center;
        font-weight     : 500;
        font-size       : 1.4rem;
        word-wrap       : break-word;
        overflow        : hidden;
        text-overflow   : ellipsis;
        padding-left    : 1rem;

        @include breakpoint(hdscreenonly2) {
            font-size: 1.2rem;
        }
    }

    .spaceRow {
        width           : 100%;
        height          : auto;
        min-height      : 5.5rem;
        color           : dark;
        border          : solid 0.1rem $light3;
        background-color: $white;
        display         : flex;
        flex-direction  : column;
        display         : flex;
        justify-content : space-around;
        align-items     : space-around;
        align-content   : space-around;
        justify-items   : space-around;

        @include breakpoint(hdscreenonly2) {
            .ends {
                padding-top: 0rem;
                padding-right: 0rem;
                font-size: .9rem;
            }
        }

        .ends {
            color      : $dark;
            padding    : 0.3rem .7rem 0rem 0rem;
            font-weight: 500;


        }

        .red {
            color      : $redColor;
            padding    : .2rem .7rem .7rem 0rem;
            font-weight: 500;
        }


        .footer {
            display       : flex;
            flex-direction: column;
            width         : 100%;
            padding       : 0.2rem 1rem 1rem 1rem;

            .expand {
                width        : 100%;
                max-height   : 4rem;
                display      : flex;
                word-wrap    : break-word;
                overflow     : hidden;
                text-overflow: ellipsis;
                padding-left : 0.1rem;
            }

            .buttonCardLeft {
                width           : 5rem;
                height          : 1.5rem;
                padding         : 1rem;
                background-color: $blue;
                color           : $white;
                border          : none;
                border-radius   : 0.3rem;
                font-size       : 1.1rem;
                font-weight     : 500;
                cursor          : pointer;
                display         : flex;
                justify-content : center;
                justify-items   : center;
                align-items     : center;
                align-content   : center;
            }
        }

        .row {
            @extend %row;
            justify-content: flex-start;
            justify-items  : flex-start;
            align-items    : center;
            align-content  : center;
            height         : 100%;
            width          : 100%;
            padding-bottom : 1rem;

            .column {
                width : 100%;
                height: 100%;
                @extend %column;
                align-items    : flex-start;
                align-content  : flex-start;
                justify-content: flex-start;
                justify-items  : flex-start;
                word-wrap      : break-word;
                overflow       : hidden;
                text-overflow  : ellipsis;
                padding        : 0;

                @include breakpoint(hdscreenonly2) {
                    margin-left: .7rem;
                }

            }

            h4 {
                color    : $dark;
                font-size: 1.3rem;

                @include breakpoint(hdscreenonly2) {
                    color    : $dark;
                    font-size: 1rem;
                }
            }

        }

        .iconBox {
            width          : 25%;
            height         : 100%;
            display        : flex;
            justify-content: flex-start;
            justify-items  : flex-start;
            align-items    : center;
            align-content  : center;
            @include breakpoint(hdscreenonly2) {
                width          : 30%;
                ;
            }
        }

        &.first {
            border-top   : none;
            border-bottom: none;
        }

        &.third {
            border-top: none;
        }

        .iconExpandMore {
            margin-left: 2rem;

            cursor : pointer;
            display: block;
            width  : 2rem;
            height : 2rem;
            @include icon($expand_more, $greyDark, 100%);
            position: relative;
            z-index : 2;

            &:hover {
                @include icon($expand_more, $blue, 100%);
            }
        }

        .iconExpandLess {
            margin-left: 2rem;
            cursor     : pointer;
            display    : block;
            width      : 2rem;
            height     : 2rem;
            @include icon($expand_less, $greyDark, 100%);
            position: relative;
            z-index : 2;

            &:hover {
                @include icon($expand_less, $blue, 100%);
            }
        }

        .iconTicket {
            display: block;
            width  : 2.3rem;
            height : 2.3rem;
            @include icon($local_activity, $greyDark, 100%);
            position: relative;
            z-index : 2;

            &:hover {
                @include icon($local_activity, $blue, 100%);
            }
        }

        .iconSpeaker {
            display: block;
            width  : 2.3rem;
            height : 2.3rem;
            @include icon($how_to_vote, $greyDark, 100%);
            position: relative;
            z-index : 2;

            &:hover {
                @include icon($how_to_vote, $blue, 100%);
            }
        }

        .iconSurvey {
            display: block;
            width  : 2.3rem;
            height : 2.3rem;
            @include icon($receipt, $greyDark, 100%);
            position: relative;
            z-index : 2;

            &:hover {
                @include icon($receipt, $blue, 100%);
            }
        }
    }

    .row {
        @extend %row;
        position        : relative;
        height          : 60%;
        width           : 100%;
        padding         : 1rem 1rem .4rem 1rem;
        border-radius   : 0 0 0.1rem 0.1rem;
        background-color: $white;
        align-items     : center;
        justify-content : center;
        align-content   : center;
        justify-items   : center;

        &.border {
            border: solid 0.1rem $light3;
        }

        .column {
            width : 100%;
            height: auto;
            @extend %column;
            align-items    : flex-start;
            align-content  : flex-start;
            justify-content: space-around;
            justify-items  : space-around;
            word-wrap      : break-word;
            overflow       : hidden;
            text-overflow  : ellipsis;
            padding        : 0rem .5rem .5rem .5rem;

            h3 {
                margin-top: .5rem;
                color     : $dark;
                font-size : 1.4rem;
                @extend %quicksandBold
            }

            p {
                margin-top: 1rem;
                color     : $dark;
            }

            .buttonInfo {
                background-color: $blueLighten;
                color           : $white;
                width           : 100%;
                height          : 2rem;
                border          : none;
                border-radius   : 0.1rem;
                font-size       : 1.1rem;
                font-weight     : 500;
                margin-top      : 1rem;
                cursor          : pointer;
            }
        }

    }

    .section {
        @extend %row;
        height: 2rem;
        width : 100%;
    }
}

.iconPeople {
    display: block;
    width  : 2.5rem;
    height : 2.5rem;
    @include icon($emoji_people, $greyDark, 100%);
    position: relative;
    z-index : 2;
}
