@import './variables.scss';

.back {
  display       : flex;
  flex-direction: row;
  width         : 100%;
  height        : 100%;
}

//RIGHT LAYOUT//
.rightLayout {
  display         : flex;
  align-items     : center;
  align-content   : center;
  justify-content : center;
  flex-direction  : column;
  width           : 65%;
  background-color: $white;
  height          : 100%;
}

.rightTop {
  display        : flex;
  width          : 100%;
  height         : 80px;
  min-height     : 80px;
  max-height     : 120px;
  justify-content: flex-start;
  align-content  : flex-start;
  justify-items  : flex-start;
  align-items    : flex-start;
}

.rightLogo {
  cursor            : pointer;
  position          : absolute;
  left              : 35%;
  top               : 5%;
  width             : 180px;
  height            : 80px;
  mask-image        : url($logo);
  -webkit-mask-image: url($logo);
  background-color  : $blueSky;
  z-index           : 19;
  transform         : scale(0.5, 0.5);

  &:hover {
    background-image: url($logo);
  }
}

.noBack {
  background-image: url($logo);
}

.rightTitle {
  @extend %quicksandRegular;
  display        : flex;
  justify-content: center;
  align-content  : center;
  flex-direction : column;
  text-align     : center;
  width          : 100%;
  color          : $blue;
  font-size      : 2rem;
  margin-bottom  : 30px;


  @include breakpoint(tabletonly) {
    margin-bottom: 0;
  }
}

.rightSubtitle {
  display        : flex;
  justify-content: center;
  align-content  : center;
  flex-direction : column;
  text-align     : center;
  width          : 100%;
  color          : $grey;
}

.tabs {
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-content  : center;
  max-width      : 100%;
  max-height     : auto;
  width          : 380px;
  height         : auto;
  min-width      : 180px;
}

.tabOff {
  margin-top      : 15px;
  margin-bottom   : 5px;
  color           : $greyLight;
  background-color: $white;
  border-bottom   : solid 2px $greyLight;
  border-top      : none;
  border-left     : none;
  border-right    : none;
  border-radius   : 0px 0px 0px 0px;
  width           : 40%;
  font-size       : 0.9rem;

  button {
    cursor       : pointer;
    margin-bottom: 0.2rem;
  }
}

.tabOn {
  margin-top      : 15px;
  margin-bottom   : 5px;
  color           : $blue;
  background-color: $white;
  border-bottom   : solid 2px $blue;
  border-top      : none;
  border-left     : none;
  border-right    : none;
  border-radius   : 0px 0px 0px 0px;
  width           : 40%;
  font-size       : 0.9rem;

  button {
    cursor       : pointer;
    margin-bottom: 0.2rem;
  }

}

.formShow {
  display        : flex;
  flex-direction : column;
  max-width      : 100%;
  max-height     : 100%;
  align-content  : center;
  justify-content: center;
}

.trasition {
  transition: transform 0.8s;
  transform : translate(20px, 20px);
}

.formHide {
  display: none;
}

///INIT FORMS///

.inputBlue {
  color        : $dark;
  background   : $white;
  border-top   : none;
  border-left  : none;
  border-right : none;
  border-bottom: none;
  width        : 100%;
  height       : 40px;
  margin-top   : 5px;
  z-index      : 1;
  font-size    : 0.9rem;

  .pointer {
    cursor: pointer;
  }

  ::placeholder {
    color: $grey;
  }

  &.spanInput {
    line-height   : 40px;
    vertical-align: middle;
  }

  &.spanInputPlaceholder {
    color: $greyLighten2;
  }
}

.inputSelect {
  display      : block;
  background   : $white;
  position     : fixed;
  max-width    : 99.5%;
  width        : 380px;
  min-width    : 180px;
  height       : 175px;
  text-align   : left;
  overflow-y   : auto;
  padding-top  : 20px;
  margin-bottom: 1px solid $greyLighten;
  z-index      : 4;

  &.hideSelect {
    display   : block;
    visibility: hidden;
    overflow  : hidden;
    height    : 0px;
  }
}

.gender {
  height: 80px;
}

.list {
  cursor         : pointer;
  display        : flex;
  flex-direction : column;
  justify-content: left;
  align-content  : flex-start;
  padding-left   : 20px;
  padding-bottom : 5px;
  z-index        : 4;

  &:hover {
    color           : $blueLighten;
    background-color: $light;
  }
}

.box {
  max-width    : 100%;
  width        : 380px;
  min-width    : 180px;
  margin-top   : 0.4rem;
  margin-bottom: 30px;

  @include breakpoint(tabletonly) {
    margin-top: 5px;
  }
}

.inputBox {
  max-width       : 100%;
  height          : 100%;
  border-top      : solid 0.5px $light3;
  border-left     : solid 0.5px $light3;
  border-right    : solid 0.5px $light3;
  border-bottom   : solid 0.5px $light3;
  border-radius   : 2.5px 2.5px 2.5px 2.5px;
  background-color: $white;
  color           : $grey;
  z-index         : 1;

  &:focus,
  &:hover {
    color: $dark;
  }
}

.inputGroup {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-content  : center;
  border-bottom  : solid 1px $light3;
  width          : 100%;
  z-index        : 1;
}

.codePhone {
  width          : 35px;
  height         : 40px;
  margin-top     : 0.3rem;
  display        : flex;
  justify-content: center;
  align-content  : center;
  justify-items  : center;
  align-items    : center;
  color          : $blue;
  background     : $white;
  border-top     : none;
  border-left    : none;
  border-right   : none;
  border-bottom  : none;
  font-weight    : 900;
}

.hide {
  display: none;
}

.btBlock {
  cursor          : pointer;
  justify-content : center;
  align-content   : center;
  text-align      : center;
  align-items     : center;
  font-weight     : 550;
  margin-top      : 18px;
  color           : $white;
  background-color: $blueLighten;
  border-radius   : 4px 4px 4px 4px;
  width           : 100%;
  line-height     : 1;
  border          : none;
  height          : 40px;
  font-size       : 18px;

  &:focus {
    background-color: $blueDark;
  }

  &:disabled {
    background-color: $blueLight;
  }
}

.disabled {
  background-color: $light2;
}

///END FORMS///////
//END RIGHT LAYOUT//
//INIT LEFT LAYOUT//
.leftLayout {
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 35%;
  height          : 100%;
  background-color: $light;
}

.circle {
  max-width      : 100%;
  max-height     : 100%;
  width          : 410px;
  height         : 410px;
  min-width      : 105px;
  min-height     : 105px;
  justify-content: center;
  align-content  : center;
  display        : flex;
}

.message {
  display         : flex;
  flex-direction  : column;
  align-content   : space-around;
  justify-content : center;
  width           : 100%;
  height          : 100%;
  border-radius   : 50%;
  background-color: $white;

  @include breakpoint(tabletonly) {
    width           : 300px;
    height          : 300px;
    display         : flex;
    flex-direction  : column;
    align-content   : space-around;
    justify-content : center;
    border-radius   : 50%;
    background-color: $white;
    margin-top      : 20%;
  }

  @include breakpoint(mobileonly) {
    border-radius: 0%;
  }
}

.headCircle {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-content  : center;
  text-align     : center;
  align-items    : center;
  justify-items  : center;
}

.circleTitle {
  font-weight  : 300;
  font-size    : 1.2rem;
  color        : $blue;
  margin-top   : 20px;
  margin-bottom: 20px;
}

.circleSubtitle {
  font-weight  : 200;
  font-size    : 0.9.2rem;
  color        : $greyBlack;
  margin-top   : 10px;
  margin-bottom: 20px;
}

.footerText {
  font-weight: 400;
  color      : $grey;
  font-size  : 0.9rem;
}

.footerCircle {
  margin-top     : 60px;
  flex-direction : column;
  display        : flex;
  justify-content: center;
  align-content  : center;
  text-align     : center;
  align-items    : center;
  justify-items  : center;
}

.btOutline {
  cursor         : pointer;
  display        : flex;
  justify-content: center;
  align-content  : center;
  text-align     : center;
  align-items    : center;
  font-weight    : 500;
  width          : 80px;
  height         : 32px;
  background     : $white;
  border         : 2px solid $blue;
  border-radius  : 4px 4px 4px 4px;
  color          : $blue;
  margin-top     : 20px;
  font-size      : 1rem;
}

.btOutline:hover {
  border    : 2px solid $blue;
  background: $blue;
  color     : $white;
}

.btOutline:focus {
  border    : 2px solid $blueLight;
  background: $blueLight;
  color     : $white;
}

//END LEFT LAYOUT//
//ICONS//
.iconPhone {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($phone);
  -webkit-mask-image: url($phone);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconAssignment {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($assignment);
  -webkit-mask-image: url($assignment);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconEvent {
  margin            : 6px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($event);
  -webkit-mask-image: url($event);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconHdr {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($hdr_strong);
  -webkit-mask-image: url($hdr_strong);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconPersonPin {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($person_pin);
  -webkit-mask-image: url($person_pin);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconHome {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($home);
  -webkit-mask-image: url($home);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);

}

.iconAccount {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($account_circle);
  -webkit-mask-image: url($account_circle);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconLock {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($lock);
  -webkit-mask-image: url($lock);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconPublic {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($public);
  -webkit-mask-image: url($public);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconEmail {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($email);
  -webkit-mask-image: url($email);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.8, 0.8);
}

.iconPerson {
  margin            : 13px 10px 0px 16px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($person);
  -webkit-mask-image: url($person);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.9, 0.9);
}

.iconExpandM {
  margin            : 10px 10px 5px 5px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($expand_more);
  -webkit-mask-image: url($expand_more);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
}

.iconExpandL {
  margin            : 10px 10px 5px 5px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($expand_less);
  -webkit-mask-image: url($expand_less);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
}

.iconVisibility {
  cursor            : pointer;
  margin            : 10px -10px 0px 0px;
  padding           : 10px 10px 10px 10px;
  mask-image        : url($visibility);
  -webkit-mask-image: url($visibility);
  background-color  : $greyLighten;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.7, 0.7);
}

.iconCheck {
  mask-image        : url($check);
  -webkit-mask-image: url($check);
  background-color  : $grey;
  width             : 24px;
  height            : 24px;
  background-repeat : no-repeat;
  transform         : scale(0.6, 0.6);

  @include breakpoint(tabletonly) {
    width: 32px;
  }
}

.iconError {
  mask-image        : url($error);
  -webkit-mask-image: url($error);
  background-color  : $danger;
  width             : 24px;
  height            : 24px;
  margin-left       : 5px;
  background-repeat : no-repeat;
  transform         : scale(0.7, 0.7);

  @include breakpoint(tabletonly) {
    margin-left  : 2px;
    padding-right: 20px;
  }
}

.iconBlue {
  background-color: $blue;
}

.iconBlueDark {
  background-color: $blueDark;
}

.iconDark {
  background-color: $greyBlack;
}

.iconGrey {
  background-color: $grey;
}

.iconGreen {
  background-color: $success;
}

//END ICONS//

/* TOOLTIP */
.tooltip {
  position    : relative;
  overflow    : hidden;
  width       : 24px;
  height      : 24px;
  margin-top  : 10px;
  margin-right: 5px;

  &.show {
    overflow: visible;

    .tooltipContent {
      opacity: 1;
    }
  }

  .tooltipContent {
    background       : $white;
    box-sizing       : border-box;
    color            : $dark;
    border-radius    : 5px 5px 5px 5px;
    border           : solid 0.5px $greyLighten;
    font-size        : 14px;
    line-height      : 1.5;
    letter-spacing   : 1px;
    max-width        : 300px;
    min-width        : 185px;
    padding          : 5px 5px 5px 5px;
    position         : absolute;
    opacity          : 0;
    transition       : all .3s ease;
    background-repeat: no-repeat;

    @include breakpoint(tabletonly) {
      max-width     : 120px;
      min-width     : 120px;
      letter-spacing: 0px;
      font-size     : 10px;
    }

    &::after {
      background   : $white;
      content      : "";
      position     : absolute;
      transform    : rotate(45deg);
      width        : 15px;
      height       : 15px;
      border-top   : solid 0px $greyLighten;
      border-left  : solid 0.5px $greyLighten;
      border-bottom: solid 0.5px $greyLighten;
      border-right : solid 0px $greyLighten;

    }
  }

  &.top {
    .tooltipContent {
      bottom   : calc(100% + 1.5em);
      left     : 50%;
      transform: translateX(-50%);

      &::after {
        bottom     : -5px;
        left       : 50%;
        margin-left: -5px;
      }
    }
  }

  &.bottom {
    .tooltipContent {
      bottom   : calc(-100% - 1.8em);
      left     : 50%;
      transform: translateX(-50%);

      &::after {
        top: -5px;
      }
    }
  }

  &.right {
    .tooltipContent {
      left     : calc(100% + 1.5em);
      top      : 44%;
      transform: translateY(-50%);

      &::after {
        left      : -8px;
        margin-top: -5px;
        top       : 44%;
      }
    }
  }

  &.left {
    .tooltipContent {
      right    : calc(100% + 1.5em);
      top      : 50%;
      transform: translateY(-50%);

      &::after {
        right     : -5px;
        margin-top: -5px;
        top       : 50%;
      }
    }
  }

}

.red {
  color: $danger;
}

.green {
  color: $success;
}

.blue {
  color: $blue;
}

.valid {
  display        : flex;
  flex-direction : row;
  align-content  : flex-start;
  align-items    : flex-start;
  text-align     : left;
  justify-content: flex-start;
  font-size      : 12.5px;
  padding-top    : 4px;
  padding-bottom : 4px;
  font-weight    : 580;

  &.example {
    flex-direction: column;
    margin-left   : 20px;
  }

  @include breakpoint(tabletonly) {
    text-align: center;
  }
}

/* TOOLTIP END*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity           : 0;
  position          : relative;
  left              : -8rem;
  width             : 60%;
  -webkit-appearance: none;
  cursor            : pointer;
}

.dateContainer {
  display        : flex;
  flex-direction : row;
  justify-content: center;
  justify-items  : center;
  align-items    : center;
  align-content  : center;
  max-width      : 100%;
  min-width      : 30%;

  .selectContainer {
    border       : none;
    border-bottom: solid 1px transparent;
    max-width    : 100%;
    min-width    : 20px;

    &:hover {
      border-bottom: solid 1px $blue;
    }

    &:active {
      border-bottom: solid 1px $blue;
    }

    .select {
      border          : none;
      height          : 2rem;
      background-color: $white;
      cursor          : pointer;

      .option {
        background-color: $white;
      }
    }
  }
}