@import './variables.scss';

.header {
    justify-content: flex-start;
    justify-items  : flex-start;
    align-content  : center;
    align-items    : center;
    width          : 100%;
    display        : flex;
    flex-direction : row;
    height         : 4rem;
    padding        : 0rem 0rem 1rem 1.1rem;

    &.post {
        margin-top: 1rem;

    }

    .headerLeft {
        margin-right   : auto;
        justify-content: flex-start;
        justify-items  : flex-start;
        align-content  : flex-start;
        align-items    : flex-start;
        display        : flex;
        width          : 100%;

        &.post {
            padding-left: 0rem;
        }

        img {
            border-radius: 50%;
            height       : 2rem;
            width        : 2rem;
        }

        .column {
            @extend %column;
        }

        .date {
            margin-left: 0.5rem;
            font-size  : 0.9rem;
            color      : $dark2;
        }

        .user {
            color    : $blue;
            font-size: 1rem;
        }

        .name {
            margin-left: 10px;
            font-size  : 1rem;
            color      : $dark2;
            @extend %quicksandBold;
        }

        .iconUser {
            cursor    : pointer;
            display   : block;
            min-width : 44px;
            min-height: 44px;
            @include icon($profile, $greyDark, 100%);
            position: relative;
            z-index : 2;
        }
    }

    .headerRight {
        margin-left    : auto;
        justify-content: flex-end;
        justify-items  : flex-end;
        align-content  : center;
        align-items    : center;
        display        : flex;

        .iconBox {
            display        : flex;
            align-items    : center;
            justify-content: center;
            border         : none;
            background     : rgba(black, 0);
            border-radius  : 50%;
            width          : 2rem;
            height         : 2rem;
            position       : absolute;
            transition     : 200ms ease-out;
            cursor         : pointer;

            .iconOption {
                cursor : pointer;
                display: block;
                width  : 1.9rem;
                height : 1.9rem;
                @include icon($more_vert, $greyDark, 100%);
                position: relative;
                z-index : 99;
            }

            &:hover {
                background: rgba(black, .15);
            }
        }

        .options {
            width           : 5rem;
            height          : .5rem;
            text-align      : center;
            color           : black;
            display         : flex;
            flex-direction  : column;
            position        : absolute;
            background-color: $white;
            margin-top      : 3rem;
            margin-right    : -1rem;

            span {
                border          : solid 1px grey;
                background-color: $white;

                &:hover {
                    background-color: grey;
                }
            }

        }
    }
}