@import './variables.scss';

.changeView {
  display      : flex;
  align-items  : center;
  width        : 36px;
  height       : 36px;
  border       : 1px solid;
  border-color : transparent;
  border-radius: 100px;
  transition   : 200ms;

  .btn {
    display        : flex;
    align-items    : center;
    justify-content: center;
    border         : none;
    background     : rgba(black, 0);
    border-radius  : 50%;
    min-width      : 36px;
    height         : 36px;
    position       : relative;
    transition     : 200ms ease-out;
    cursor         : pointer;

    .iconViewList {
      display: block;
      width  : 24px;
      height : 24px;
      @include icon($view_list, $greyDark, 100%);
      position: relative;
      z-index : 2;
    }

    .iconViewModule {
      display: block;
      width  : 24px;
      height : 24px;
      @include icon($view_module, $greyDark, 100%);
      position: relative;
      z-index : 2;
    }

    &:hover {
      background: rgba(black, .15);
    }
  }
}

.list {
  width: auto;
}