@import './variables.scss';

.loginScreen{
	width: 100%;
	height: 100%;

	background-color: $light;
	
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column nowrap;
}

.topBar{
	width: 100%;
	height: 10%;
	flex: 0 0 10%;
	
	display: flex;
	flex-flow: row nowrap;

	justify-content: space-between;

	.logo{
		width: 10%;
		height: 100%;
		@include icon($logo,$blueSky,55%);
		cursor: pointer;

		&:hover{
			background-image: url($logo);
			background-size: 55%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.side{
		width: 20%;
		height: 100%;

		display: flex;
		flex-flow: row nowrap;

		& > div{
			width: 50%;
			height: 100%;

			display: flex;

			justify-content: center;
			align-items: center;

			color: $blue;

			@extend %quicksandRegular;
			font-size: .8rem;
		
			a{

				text-decoration: none;
				display: flex;
				width: 65%;
				height: 40%;
				background-color: $white;
				border: 1px solid $blueLight;
				border-radius: 3px;
				color: $blue;
				justify-content: center;
				align-items: center;


				&:hover{
					background-color: $blueLighten;
					color: $white;
				}
			}
		}
	}
}

.login{
	display: flex;
	width: 25%;
	height: 90%;
	padding-top: 4rem;

	flex: 0 0 90%;
	justify-content: flex-start;
	flex-flow: column nowrap;

	.icon{
		width: 100%;
		height: 7.5%;
		@include icon($profile,$blue,contain);
	}

	h1{
		@extend %quicksandRegular;
		text-align: center;
		color: $blue;
		font-size: 1.4rem;
		margin: 1rem 0;

		small{
			color: $greyBlack;
			font-size: .9rem;

		}
	}
}
