@import './variables.scss';

.btnUrl {
    display: flex;
    align-items: center;
    width: 36px;
    position: absolute;
    left: 0;
    top: 0;
    height: 36px;
    border: 1px solid;
    border-color: transparent;
    border-radius: 100px;
    transition: 200ms;
    overflow: hidden;
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: rgba(black, 0);
        border-radius: 50%;
        min-width: 36px;
        height: 36px;
        position: relative;
        transition: 200ms ease-out;
        i {
        display: block;
        width: 24px;
        height: 24px;
        @include icon($public, $blueDark, 100%);
        position: relative;
        z-index: 2;
        }

        &:hover{
            cursor: pointer;
        }
    }
    a{
        @extend %quicksandMedium;
        font-size: 12px;
        border: none;
        background: none;
        padding: 0 16px 0 5px;
        transition: 200ms;
        color: $blueDark;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &.expander {
        width: 200px;
        max-width: 80%;
        background-color: $light2;          
    }
    &:hover {
        background-color: $light2;
    }
}
