@import './variables.scss';

main {
    width         : 100%;
    height        : 100%;
    display       : flex;
    background    : $light;
    position      : relative;
    flex-direction: column;

    ::-webkit-scrollbar {
        display: none;
    }
}

.container {
    display: flex;
    align-content: flex-start;
    overflow-y: scroll;
    flex-flow: row wrap;
    overflow-x: hidden;
    margin: 1rem 4rem 0;

    &.list{

        display: flex;
        align-content: flex-start;
        overflow-y: scroll;
        flex-flow: row wrap;
        overflow-x: hidden;
        margin: 1rem 4rem 0;

        &>[class*="card"]:nth-child(3n-2){
            margin-left: 0rem;
        }
    
        &>[class*="card"]:nth-child(3n){
            margin-right: 0rem;
        }
    }

    &:not(.list)>[class*="card"] {
        height: 16rem;
        margin: 0rem 1rem 1rem 0rem;
        width: calc(25% - 1rem);
    }

    &:not(.list)>[class*="card"]:nth-child(4n-3){
        margin-left: 0rem;
    }

    &>[class*="card"]:nth-child(4n-1):not(.inactive){
        margin-right: 1rem;
    }

    &:not(.list)>[class*="card"]:nth-child(4n){
        margin-right: 0rem;
    }

}

.toolbar {
    display    : flex;
    align-items: center;
    height     : 50px;
    margin-top : 16px;
    margin: 1rem 4rem 0;
    menu {
        display: flex;
        button {
            margin-left: 0px;
        }
    }
}

.infoEmpty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    background: $light;
    z-index: 0;
    p {
        font-size: 20px;
        color: $blueUltraSoft;
        margin-bottom: 16px;
    }
    i {
        display: block;
        width: 96px;
        height: 96px;
        @include icon($bussiness, $blueUltraSoft, 100%);
    }
}
