@import './variables.scss';

.slide {
    width          : 100%;
    height         : 100%;
    flex-direction : row;
    display        : flex;
    justify-content: center;
    justify-items  : center;
    align-items    : center;
    align-content  : center;
}

.iconRight {
    cursor : pointer;
    display: block;
    width  : 4rem;
    height : 4rem;
    @include icon($chevron_right, $white, 100%);
    position: relative;
    z-index : 2;

    &:hover {
        @include icon($chevron_right, $blue, 100%);
    }
}

.iconLeft {
    cursor : pointer;
    display: block;
    width  : 4rem;
    height : 4rem;
    @include icon($chevron_left, $white, 100%);
    position: relative;
    z-index : 2;

    &:hover {
        @include icon($chevron_left, $blue, 100%);
    }
}

.iconBoxLeft {
    width           : 5%;
    height          : 8%;
    display         : flex;
    justify-items   : center;
    justify-content : center;
    align-content   : center;
    align-items     : center;
    position        : relative;
    left            : 5%;
    z-index         : 99;
    background-color: rgba(#000000, .5%);
}

.iconBoxRight {
    width           : 5%;
    height          : 8%;
    display         : flex;
    justify-items   : center;
    justify-content : center;
    align-content   : center;
    align-items     : center;
    position        : relative;
    right           : 5%;
    z-index         : 99;
    background-color: rgba(#000000, .5%);
}

.img {
    display: none;
    width  : 100%;
    height : 100%;
}

.active {
    display   : flex;
    z-index   : 1;
    width     : auto;
    max-width : 100%;
    height    : auto;
    max-height: 100%;
}
