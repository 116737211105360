@import './variables.scss';

.disapproved {
    color: $greyLighten;
}

.approved {
    color: $green;
}

.error {
    color: $danger;
}

.errorMessage {
    color          : $dark;
    display        : flex;
    flex-direction : row;
    justify-items  : flex-end;
    justify-content: flex-end;
    align-content  : center;
    align-items    : center;
    width          : 95%;
    height         : 1.5rem;
    position       : relative;
    padding-top    : 1rem;

    span {
        font-weight: 500;
        font-size  : 1.2rem;

        &.check {
            color: $green;
        }
    }

    .iconError {
        width : 1.5rem;
        height: 1.5rem;
        @include icon($error2, $danger, 100%);
        z-index     : 3;
        margin-right: 1rem;
    }

    .iconCheck {
        width : 1.5rem;
        height: 1.5rem;
        @include icon($check_circle, $greyLight, 100%);
        z-index     : 3;
        margin-right: 1rem;

        &.check {
            @include icon($check_circle, $green, 100%);
        }
    }
}


.iconVisibility {
    cursor: pointer;
    width : 1.3rem;
    height: 1.3rem;
    @include icon($visibility, $greyDark, 100%);
    z-index: 2;
}
