@import './variables.scss';

.contentListOption {
    position       : absolute;
    right          : 0;
    width          : 1.6rem;
    height         : 1.6rem;
    display        : flex;
    justify-content: center;
    align-content  : center;
    align-items    : center;
    justify-items  : center;
    cursor         : pointer;

    &:hover {
        background-color: $greyLight3;
        border-radius   : 50%;
        color           : $white;
    }

    &.open{

        button{
            display: initial;
        }

        .overflow{
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(black, 0);
            z-index: 98;
        }

    }

    .iconMoreVert {
        width : 100%;
        height: 100%;
        @include icon($more_vert, $dark, 100%);
    }

    .listOption{
        display: flex;
        flex-flow: column nowrap;
        position: absolute;
        top: 15px;
        color: $dark;
        box-shadow: 0px 0px 4px 0px;
        background-color: $white;
        border-radius: 2px;
        z-index: 99;
        &::nth-child(){
            border: none;
        }

    }

    &>::nth-child(){
        border: none;
    }
    
}