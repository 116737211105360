.btn {
  min-width: 56px;
  min-height: 36px;
  border: none;
  font-size: 16px;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0 16px;
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: black;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: 200ms ease-in;
  }
  span {
    letter-spacing: 2px;
    position: relative;
    z-index: 2;
  }
  &.filled {
    background: rgba(black, .25);
    color: white;
  }
  &.outlined {
    border: 1px solid;
    border-color: rgba(black, .25);
    background: white !important;
    color: black;
  }
  &:hover {
    &::before {
      opacity: .1;
    }
  }
  &:active {
    &::before {
      opacity: .25;
    }
  }
}