@import './variables.scss';

.centerUser {
    width          : 50%;
    height         : auto;
    display        : flex;
    justify-content: flex-start;
    justify-items  : flex-start;
    align-items    : center;
    align-content  : center;
    flex-direction : column;
    margin-top     : 2rem;
}