@import './variables.scss';

.comment {
    display        : flex;
    justify-content: center;
    justify-items  : center;
    align-content  : center;
    align-items    : center;
    width          : 94%;
    

    &.view {
        width : 100%;
        height: 4rem;
    }

    .img {
        width        : 1.9rem;
        height       : 1.9rem;
        border-radius: 50%;
        margin-top   : .1rem;
    }

    .iconUser {
        cursor : pointer;
        display: block;
        width  : 2rem;
        height : 2rem;
        @include icon($profile, $greyDark, 100%);
        position  : relative;
        z-index   : 2;
        margin-top: .1rem;
    }

    textarea {
        display         : flex;
        justify-content : center;
        justify-items   : center;
        align-items     : center;
        align-content   : center;
        resize          : none;
        background-color: $light2;
        border          : solid 0.5px $light3;
        border-radius   : 0.1rem;
        width           : 85%;
        text-decoration : none;
        font-size       : 1.2rem;
        padding-left    : 1rem;
        height          : 2rem;
        color           : $dark;
        overflow        : hidden;
        z-index: 99 ;

        &.modal {
            width: 60%;
        }

        &:focus {
            color           : $darkness;
            background-color: $white;
        }
    }



    .button {
        color          : $grey;
        display        : flex;
        justify-content: center;
        align-content  : center;
        justify-items  : center;
        align-items    : center;
        border         : none;
        height         : 2rem;
        width          : auto;
        background     : $white;
        cursor         : pointer;
        font-size      : 1.1rem;
        @extend %quicksandBold;
        border: solid 0.1rem $white;


        &:hover {
            border: solid 0.1rem $light;
            color : $blue;
        }
    }
}