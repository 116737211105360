@import './variables.scss';

.modal {
    position        : fixed;
    color           : $anotherGrey;
    top             : 30%;
    left            : 40%;
    width           : 25%;
    height          : 18%;
    min-height      : 8rem;
    border          : solid 1px $greyDarken3;
    background-color: $white;
    text-align      : center;
    z-index         : 99;
    box-shadow      : 0px 0px 4px 0px;
    @include breakpoint(mobileonly) {

        height: auto;
    }

    @include breakpoint(tabletonly) {
        height: auto;
    }
    .row {
        display        : flex;
        width          : 100%;
        flex-direction : row;
        justify-content: flex-end;
        justify-items  : flex-end;
    }

    .iconClose {
        margin-top  : .4rem;
        margin-right: .4rem;
        cursor      : pointer;
        display     : block;
        width       : 24px;
        height      : 24px;
        @include icon($close, $anotherGrey, 100%);
        position: relative;
        z-index : 99;
        &:hover{
            @include icon($close, $greyColor, 100%);
        }
    }
}
