@import './variables.scss';

.contentRight {
    display        : flex;
    flex-direction : column;
    width          : 70%;
    height         : 100%;
    margin-right   : 7%;
    justify-content: center;
    align-items    : center;
    align-content  : center;
    justify-items  : center;
    margin-top     : 22%;
    position       : relative;
    @include breakpoint(hdscreenonly2) {
        height         : 80%;
        margin-top     : 10%;
    }
}

.title {
    display        : flex;
    flex-direction : row;
    width          : 100%;
    height         : 1rem;
    justify-items  : flex-start;
    justify-content: flex-start;
    align-items    : center;
    align-content  : center;
    padding        : 1rem;

    h2 {
        color: $dark;
    }

    span {
        color: $dark;
    }

    .btnGroup {
        width  : 14rem;
        display: flex;

        .btnBlue {
            color: $white;
            @extend %quicksandMedium;
            padding         : .2rem .9rem .2rem .9rem;
            background-color: $blueLight;
            border          : none;
            font-size       : 1.1rem;
            border-radius   : 4px;
            margin-left     : 1rem;
            margin-right    : 1rem;
            width           : 5rem;
            height          : 2rem;
            text-align      : center;
            font-weight     : 500;

            &.click {
                background-color: $blueDark;
            }

            &.active {
                background-color: $blueBtn;
                cursor          : pointer;
            }
        }

        .btnWhite {
            color: $dark;
            @extend %quicksandMedium;
            border     : none;
            font-weight: 500;
            font-size  : 1.1rem;
            margin-left: 1rem;
        }
    }
}

.form {
    display       : flex;
    flex-direction: row;
    width         : 100%;
    height        : 100%;

    .column {
        display       : flex;
        flex-direction: column;
        width         : 50%;

        .divRow {
            display       : flex;
            flex-direction: row;
        }

        .divInput {
            display       : flex;
            flex-direction: column;
            padding       : 0rem .8rem 0rem .8rem;
            height        : 5rem;

            label {
                color         : $dark;
                padding-bottom: .2rem;
                @extend %quicksandMedium;
                font-size: .9rem;
            }

            .input {
                width        : 95.5%;
                height       : 2.2rem;
                padding-left : .5rem;
                border       : solid $greyDarken3 2px;
                border-radius: 2px;
                color        : $dark;
                font-size    : 1rem;
                @extend %quicksandMedium;
                @include breakpoint(hdscreenonly2) {
                    width        : 99%;
                }
            }

            .phone {
                width        : 76%;
                margin-right : -1%;
                height       : 2.2rem;
                border       : solid $greyDarken3 2px;
                border-left  : none;
                border-radius: 2px;
                color        : $dark;
                font-size    : 1rem;
                @extend %quicksandMedium;
                @include breakpoint(hdscreenonly2) {
                    width        : 80%;
                }


                &.code {
                    width       : 20%;
                    padding-left: 1rem;
                    color       : $blue;
                    border-left : solid $greyDarken3 2px;
                    border-right: none;
                }
            }


            .inputIcon {
                width        : 86%;
                height       : 2.2rem;
                padding-left : .5rem;
                border-left  : solid $greyDarken3 2px;
                border-top   : solid $greyDarken3 2px;
                border-bottom: solid $greyDarken3 2px;
                border-right : none;
                border-radius: 2px;
                color        : $dark;
                font-size    : 1rem;
                @extend %quicksandMedium;
            }

            .divIcons {
                display        : flex;
                justify-items  : center;
                align-items    : center;
                align-content  : center;
                justify-content: center;
                width          : 2.4rem;
                height         : 2.2rem;
                border-left    : none;
                border-top     : solid $greyDarken3 2px;
                border-bottom  : solid $greyDarken3 2px;
                border-right   : solid $greyDarken3 2px;
                margin-left    : -.2rem;

                .iconVisibility {
                    cursor: pointer;
                    width : 1.3rem;
                    height: 1.3rem;
                    @include icon($visibility, $greyDark, 100%);
                    z-index: 2;
                }
            }
        }
    }
}

.disable {
    background-color: $greyLight2;
}

.enable {
    background-color: $white;
}
