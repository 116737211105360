//////COLORS//////
$white           :#FFFFFF;
$light           :#F7F7F7;
$light2          :#F2F2F2;
$light3          :#DEE2E4;
$greyLight       :#D6DCE5;
$greyLight2      :#E7E6E6;
$greyLight3      :#D9D8D8;
$greyLight4      :#EDEDED;
$greyLighten     :#CDCDCD;
$greyLighten2    :#A5A5A5;
$greyDarken      :#ADB9CA;
$greyDarken2     :#8598B0;
$greyDarken3     :#BFBFBF;
$grey            :#A6A6A6;
$anotherGrey     :#727272;
$greyDark        :#A2A1A1;
$greyBlack       :#7F7F7F;
$dark            :#404040;
$dark2           :#595959;
$darkness        :#262626;
$blueUltraSoft   :#ABC4DB;
$blueSky         :#83C9E8;
$blueLight       :#6BC6EB;
$blueLighten     :#00B0F0;
$blue            :#2FB1E5;
$blueDarker      :#3D849F;
$blueDark        :#1994C5;
$blueDarkest     :#2E75B6;
$blueSoftly      :#AECDEA;
$blueUltraSoftly :#ACC4DB;
$success         :#96CE5B;
$danger          :#FF2D2D;
$grayBlue        :#8497b0;
$grayBlueDarker  :#596f8d;
$green           :#92D050;
$approved        :#548235;
$shadow          :rgba(#000000, .6);
$blueGrey        :#ACC4DB;
$black           :#000000;
$yellow          :#FFC000;
$redColor        :#C00000;
$greyColor       :#D9D9D9;
$blueBtn         :#0EB0F0;


//Cards Colors
$greenCard           :#A9D18E;
$yellowCard          :#FFD966;
$redCard             :#FF5D5D;
$blueCard            :#9DC3E6;
//////////////////
//////////ICONS/////////
$phone               :"../images/icons/Outline/Action/outline-phone-24px.svg";
$home                :"../images/icons/Outline/Action/outline-home-24px.svg";
$home_baseline       :"../images/icons/Fill/Action/baseline-home-24px.svg";
$account_circle      :"../images/icons/Outline/Action/outline-account_circle-24px.svg";
$account_circle_fill :"../images/icons/Fill/Action/account_circle-24px.svg";
$lock                :"../images/icons/Outline/Action/outline-lock-24px.svg";
$public              :"../images/icons/Fill/Social/baseline-public-24px.svg";
$email               :"../images/icons/Outline/Communication/outline-email-24px.svg";
$fillEmail           :"../images/icons/Fill/Communication/baseline-email-24px.svg";
$person              :"../images/icons/Outline/Social/outline-person-24px.svg";
$expand_more         :"../images/icons/Fill/Navigation/outline-expand_more-24px.svg";
$expand_less         :"../images/icons/Fill/Navigation/outline-expand_less-24px.svg";
$visibility          :"../images/icons/Outline/Action/outline-visibility-24px.svg";
$check               :"../images/icons/Fill/Navigation/baseline-check-24px.svg";
$error               :"../images/icons/Fill/Alert/baseline-error-24px.svg";
$search              :"../images/icons/Fill/Action/baseline-search-24px.svg";
$logo                :"../images/kommu.svg";
$apps                :"../images/icons/Fill/Navigation/baseline-apps-24px.svg";
$profile             :"../images/icons/Fill/Action/baseline-account_circle-24px.svg";
$message             :"../images/icons/Fill/Communication/baseline-chat_bubble-24px.svg";
$conf                :"../images/icons/Fill/Editor/baseline-bar_chart-24px.svg";
$notification        :"../images/icons/Fill/Social/baseline-notifications-24px.svg";
$reader              :"../images/icons/Fill/Action/baseline-chrome_reader_mode-24px.svg";
$event               :"../images/icons/Fill/Action/baseline-event-24px.svg";
$eventGray           :"../images/icons/Fill/Action/baseline-event-24px.svg";
$blog                :"../images/icons/Fill/Content/baseline-create-24px.svg";
$forum               :"../images/icons/Fill/Social/baseline-group-24px.svg";
$website             :"../images/icons/Fill/Action/baseline-language-24px.svg";
$video               :"../images/icons/Fill/Av/baseline-videocam-24px.svg";
$chevron_left        :"../images/icons/Fill/Navigation/baseline-chevron_left-24px.svg";
$chevron_right       :"../images/icons/Fill/Navigation/baseline-chevron_right-24px.svg";
$close               :"../images/icons/Fill/Navigation/baseline-close-24px.svg";
$local_activity      :"../images/icons/Outline/Maps/outline-local_activity-24px.svg";
$local_offer         :"../images/icons/Outline/Maps/outline-local_offer-24px.svg";
$language            :"../images/icons/Fill/Action/baseline-language-24px.svg";
$how_to_vote         :"../images/icons/Outline/Content/outline-how_to_vote-24px.svg";
$bussiness           :"../images/icons/Outline/Places/outline-business_center-24px.svg";
$work                :"../images/icons/Outline/Action/outline-work_outline-24px.svg";
$fillWork            :"../images/icons/Fill/Action/baseline-work-24px.svg";
$developer_board     :"../images/icons/Outline/Hardware/outline-developer_board-24px.svg";
$point               :"../images/icons/Fill/Action/circle-solid.svg";
$group               :"../images/icons/Fill/Social/baseline-group-24px.svg";
$video               :"../images/icons/Fill/Av/baseline-play_circle_filled-24px.svg";
$volume              :"../images/icons/Fill/Av/baseline-volume_down-24px.svg";
$image               :"../images/icons/Fill/Image/baseline-image-24px.svg";
$document            :"../images/icons/Fill/Editor/baseline-insert_drive_file-24px.svg";
$dashboard           :"../images/icons/Fill/Action/dashboard-24px.svg";
$bubbleChart         :"../images/icons/Fill/Editor/bubble_chart-24px.svg";
$cancel              :"../images/icons/Fill/Navigation/cancel-24px.svg";
$arrow_drop_down     :"../images/icons/Fill/Navigation/arrow_drop_down-24px.svg";
$text                :"../images/icons/Fill/Editor/title-24px.svg";
$number              :"../images/icons/Outline/Image/looks_one-24px.svg";
$arroba              :"../images/icons/Outline/Action/email_alternative.svg";
$phone               :"../images/icons/Outline/Communication/outline-phone-24px.svg";
$date                :"../images/icons/Outline/Action/date_range-24px.svg";
$schedule            :"../images/icons/Outline/Action/schedule-24px.svg";
$earth               :"../images/icons/Fill/Social/baseline-public-24px.svg";
$image_outline       :"../images/icons/Outline/Image/image-24px.svg";
$file                :"../images/icons/Outline/Editor/insert_drive_file-24px.svg";
$link                :"../images/icons/Outline/Content/link-24px.svg";
$checkbox            :"../images/icons/Outline/Toggle/check_box-24px.svg";
$select              :"../images/icons/Outline/Toggle/select_alternative.svg";
$hourglass           :"../images/icons/Outline/Action/hourglass_empty-24px.svg";
$progress            :"../images/icons/Fill/Action/trending_up-24px.svg";
$add_circle          :"../images/icons/Fill/Content/ic_add_circle_48px.svg";
$file_copy           :"../images/icons/Outline/Content/outline-file_copy-24px.svg";
$happy_face          :"../images/icons/Outline/Social/outline-sentiment_satisfied-24px.svg";
$view_list           :"../images/icons/Fill/Action/view_list.svg";
$view_module         :"../images/icons/Fill/Action/view_module.svg";
$build               :"../images/icons/Fill/Action/build-24px.svg";
$setting             :"../images/icons/Fill/Action/ic_settings_48px.svg";
$more_vert           :"../images/icons/Fill/Navigation/more_vert.svg";
$chrome_reader_mode  :"../images/icons/Outline/Action/chrome_reader_mode-24px.svg";
$receipt             :"../images/icons/Outline/Action/receipt-24px.svg";
$amp_stories         :"../images/icons/Outline/Content/amp_stories-24px.svg";
$meeting_room        :"../images/icons/Outline/Places/meeting_room-24px.svg";
$list_alt            :"../images/icons/Fill/Communication/baseline-list_alt-24px.svg";
$person              :"../images/icons/Fill/Social/person-24px.svg";
$person_group        :"../images/icons/Fill/Social/people_alt-24px.svg";
$person_out          :"../images/icons/Outline/Social/outline-person-24px.svg";
$group_out           :"../images/icons/Outline/Social/outline-group-24px.svg";
$favorite_fill       :"../images/icons/Fill/Action/favorite-24px.svg";
$pause               :"../images/icons/Fill/Av/pause-24px.svg";
$play_arrow          :"../images/icons/Fill/Av/play_arrow-24px.svg";
$volumen_off         :"../images/icons/Fill/Av/volume_off-24px.svg";
$volumen_up          :"../images/icons/Fill/Av/volume_up-24px.svg";
$forum               :"../images/icons/Fill/Communication/forum-24px.svg";
$fullscreen_exit     :"../images/icons/Fill/Navigation/fullscreen_exit-24px.svg";
$fullscreen          :"../images/icons/Fill/Navigation/fullscreen-24px.svg";
$emoji_people        :"../images/icons/Fill/Social/emoji_people-24px.svg";
$favorite_outline    :"../images/icons/Outline/Action/favorite_border-24px.svg";
$question_answer     :"../images/icons/Outline/Communication/question_answer-24px.svg";
$slideshow           :"../images/icons/Outline/Image/slideshow-24px.svg";
$sentiment_satisfied :"../images/icons/Outline/Social/sentiment_satisfied_alt-24px.svg";
$refresh             :"../images/icons/Fill/Navigation/refresh-24px.svg";
$brush               :"../images/icons/Outline/Image/brush-24px.svg";
$radio               :"../images/icons/Fill/Toggle/radio_button_checked-24px.svg";
$storefront          :"../images/icons/Outline/Places/storefront-24px.svg";
$add                 :"../images/icons/Fill/Content/baseline-add-24px.svg";
$check_circle        :"../images/icons/Fill/Action/check_circle-24px.svg";
$error2              :"../images/icons/Fill/Alert/error-24px.svg";
$assignment          :"../images/icons/Fill/Action/assignment_ind-24px.svg";
$hdr_strong          :"../images/icons/Fill/Image/hdr_strong-24px.svg";
$person_pin          :"../images/icons/Fill/Maps/person_pin_circle-24px.svg";

@mixin icon($route, $color: 0, $size:100%, $position:center, $repeat: no-repeat) {
	mask-size            : $size;
	mask-image           : url($route);
	mask-position        : $position;
	mask-repeat          : $repeat;
	-webkit-mask-size    : $size;
	-webkit-mask-image   : url($route);
	-webkit-mask-position: $position;
	-webkit-mask-repeat  : $repeat;

	@if $color !=0 {
		background-color: $color;
	}

}

@mixin breakpoint($point) {
	@if $point==mobileonly {
		@media (max-width: 600px) {
			@content ;
		}
	}

	@if $point==tabletonly {
		@media (max-width: 1024px) {
			@content ;
		}
	}

	@if $point==hdscreenonly {
		@media (max-width: 1280px) {
			@content ;
		}
	}

	@if $point==hdscreenonly2 {
		@media (max-width: 1366px) {
			@content ;
		}
	}

	@if $point==fhscreenonly {
		@media (max-width: 1920px) {
			@content ;
		}
	}
}

/////////Generic clases/////////

%quicksandLight {
	font-family: 'Quicksand', sans-serif;
	font-style: normal;
	font-weight: 300;
}

%quicksandRegular {
	font-family: 'Quicksand', sans-serif;
	font-style: normal;
	font-weight: 400;
}

%quicksandMedium {
	font-family: 'Quicksand', sans-serif;
	font-style: normal;
	font-weight: 500;
}

%quicksandBold {
	font-family: 'Quicksand', sans-serif;
	font-style: normal;
	font-weight: 700;
}

%notAllowed {
	cursor: not-allowed;
}

%row {
	display       : flex;
	flex-direction: row;
}

%column {
	display       : flex;
	flex-direction: column;
}