@import './variables.scss';

.boxOption {
    margin-right   : auto;
    width          : auto;
    justify-content: flex-end;
    justify-items  : flex-end;
    align-content  : center;
    align-items    : center;
    display        : flex;
    position       : relative;
    padding-right  : 1rem;
    padding-bottom : 1rem;

    &.modal {
        width: 100%;
    }

    .iconBox {
        display        : flex;
        align-items    : center;
        justify-content: center;
        border-radius  : 50%;
        width          : 2rem;
        height         : 2rem;
        position       : absolute;
        cursor         : pointer;

        .iconOption {
            cursor : pointer;
            display: block;
            width  : 1.5rem;
            height : 1.5rem;
            @include icon($more_vert, $greyDark, 100%);
            position: relative;
        }

        &:hover,
        &:focus {
            background: rgba(black, .15);
        }
    }

    .options {
        width           : 5rem;
        height          : .5rem;
        text-align      : left;
        display         : flex;
        flex-direction  : column;
        position        : absolute;
        background-color: $white;
        margin-top      : 1rem;
        margin-right    : 1rem;
        z-index         : 99;
        border          : none;

        span {
            width           : 100%;
            background-color: $white;
            padding         : .4rem 0rem .4rem 1rem;
            @extend %quicksandBold;
            color       : $dark;
            border-top  : solid 1px $light3;
            border-left : solid 1px $light3;
            border-right: solid 1px $light3;

            &:hover {
                background-color: $greyLighten;
            }

            &.botton {
                border-top   : none;
                border-bottom: solid 1px $light3;
            }
        }

    }
}