@import './variables.scss';

.commentsBox {
    width         : 98%;
    height        : auto;
    word-wrap     : break-word;
    overflow-y    : hidden;
    overflow-x    : hidden;
    text-overflow : ellipsis;
    display       : flex;
    flex-direction: column;
    margin-top    : .5rem;

    .row {
        @extend %row;
        min-height     : 6rem;
        justify-content: flex-start;
        justify-items  : flex-start;
        align-content  : flex-start;
        align-items    : flex-start;
        padding-bottom : 1.2rem;
        padding-top    : 1.2rem;

        &.view {
            padding    : 0;
            min-height : 2.5rem;
            margin-left: 0.2rem;
            margin-top : 1rem;
        }

        &.comment {
            margin-top : 0;
            width      : 100%;
            margin-left: 3.5rem;
        }
    }

    .column {
        @extend %column;
        width : 50%;
        height: auto;
    }

    p {
        color      : $dark;
        text-align : justify;
        cursor     : pointer;
        margin-left: 1rem;
        padding-bottom: .5rem;

    }

    .author {
        font-weight  : 600;
        color        : $dark2;
        padding-right: 1rem;
    }

    .profileBox {
        width : 10%;
        height: 10%;

        img {
            width        : 2.5rem;
            height       : 2.5rem;
            border-radius: 50%;
        }
    }

    .tag {
        display         : flex;
        justify-content : center;
        justify-items   : center;
        align-content   : center;
        align-items     : center;
        width           : 2.5rem;
        height          : 1rem;
        background-color: $greyLight;
        color           : $darkness;
        position        : relative;
        font-size       : 0.7rem;
    }
}

.iconUser {
    cursor : pointer;
    display: block;
    width  : 2.5rem;
    height : 2.5rem;
    @include icon($profile, $greyDark, 100%);
    position: relative;
    z-index : 2;
}

.iconBox {
    width          : 20%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    justify-items  : center;
    align-content  : space-around;
    align-items    : center;
    padding-top    : 1rem;
    padding-left   : 1rem;

    .view {
        width: 10%;
    }

    .iconFavorite {
        cursor : pointer;
        display: block;
        width  : 1.2rem;
        height : 1.2rem;
        @include icon($favorite_outline, $greyDark, 100%);
        position: relative;
        z-index : 2;

        &:hover {
            @include icon($favorite_outline, $danger, 100%);
        }

        &.redLike {
            @include icon($favorite_fill, $danger, 100%);
        }
    }

    .iconForum {
        cursor : pointer;
        display: block;
        width  : 1.2rem;
        height : 1.2rem;
        @include icon($question_answer, $greyDark, 100%);
        position   : relative;
        z-index    : 2;
        margin-left: 1rem;
    }
}

.commentModal {
    width          : 100%;
    height         : 70%;
    display        : flex;
    flex-direction : column;
    justify-items  : space-between;
    justify-content: space-between;
    align-items    : center;
    align-content  : center;
    @extend %quicksandMedium;

    h2 {
        @extend %quicksandBold;
    }

    button {
        text-align      : center;
        cursor          : pointer;
        width           : 50%;
        height          : 3rem;
        border          : none;
        color           : $white;
        background-color: $blue;
        font-size       : 1.2rem;
        @extend %quicksandBold;

        &:hover {
            background-color: $blueDark;
        }
    }
}