@import './variables.scss';

.list {

    @extend %quicksandMedium;
    color: $dark;
    display: none;
    background-color: transparent;
    top: 15px;
    border: none;
    border-top: 1px solid $greyDarken3;
    z-index: 9999;
    padding: 2px 4px;
    text-transform: capitalize;

    &:hover{
        background-color: $light2;
    }

    &:focus{
        background-color: $greyLight3;
    }
    
}
