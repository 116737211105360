@import './variables.scss';

main {
    width: 100%;
    height: 100%;
    display: flex;
    background: $light;
    position: relative;
    .infoEmpty {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        background: $light;
        z-index: 0;
        p {
            font-size: 20px;
            color: $blueUltraSoft;
            margin-bottom: 16px;
        }
        i {
            display: block;
            width: 96px;
            height: 96px;
            @include icon($happy_face, $blueUltraSoft, 100%);
        }
    }
    .container {
        flex-grow: 1;
        height: 100%;
        padding: 0px 32px;
        .toolbar {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 48px;
            margin-top: 16px;
            menu {
                display: flex;
                margin-left: auto;
                button {
                    margin-left: 32px;
                }
            }
        }
        .table {
            width: 100%;
            height: calc(100% - (48px + 16px*2));
            margin-bottom: 16px;
            position: relative;
            .headtable {
                width: 100%;
                min-height: 32px; 
                display: flex;
                align-items: center;
                background: rgba(black, .15);
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
            }
            >section {
                width: 100%;
                height: calc(100% - (32px + 16px));
                margin-top: 16px;
                overflow: auto;
            }
        }
    }
}