@import './variables.scss';

%toolBtn {
	flex   : 0 0 10%;
	width  : 10%;
	height : 100%;
	cursor : pointer;
	z-index: 9;

	&:hover {
		background-color: #2FB1E5
	}
}

.menubar {
	z-index         : 9;
	width           : 100%;
	height          : 46px;
	background-color: white;
	position        : relative;
	box-shadow      : 2px 0 10px $greyLight;


	display        : flex;
	flex-flow      : row nowrap;
	justify-content: space-between;

	.img {
		width              : 5%;
		background-image   : url($logo);
		background-size    : 3.5rem;
		background-repeat  : no-repeat;
		background-position: center;
		cursor             : pointer;
	}
}

.menuSide {
	position: relative;
	flex    : 0 0 40%;
	width   : 40%;
	height  : 100%;
	padding : 0% 1%;

	display         : flex;
	flex-flow       : row nowrap;
	justify-content : flex-start;
	align-items     : center;
	background-color: $white;

	.menuBtn {
		flex     : 0 0 12%;
		width    : 12%;
		min-width: 1rem;
		max-width: 1.325rem;
		height   : 50%;
		@include icon($apps, $greyDark, 100%);
		cursor     : pointer;
		margin-left: 1.6rem;

		&:hover {
			background-color: #2FB1E5
		}
	}
}

.menu {
	position: absolute;
	top     : 0;
	left    : 0;
	width   : 16rem;
	height  : 20rem;
	border  : 1px solid $light3;

	background-color: white;
	box-shadow      : 0px 2px 5px $greyBlack;
	margin-top      : -18rem;
	transition      : margin .5s;
	z-index         : -2;

	display        : flex;
	flex-flow      : row wrap;
	justify-content: flex-start;

	&.show {
		margin-top: 2.5rem;
	}

	&>a {
		flex           : 0 0 35%;
		width          : 35%;
		height         : 5rem;
		margin-left    : 10%;
		text-decoration: none;

		display        : flex;
		flex-flow      : column nowrap;
		align-items    : center;
		justify-content: center;
		cursor         : pointer;

		.icon {
			flex  : 0 0 50%;
			width : 100%;
			height: 50%;
		}

		&:hover {
			box-shadow: 0 0 1px $dark;

			.icon {
				background-color: $blue;
			}

			.text {
				color: $dark;
			}
		}

		&:active {
			background-color: $greyLight2;

			.icon {
				background-color: $blueDarker;
			}

			.text {
				color: $blueDarker;
			}
		}

		.text {
			@extend %quicksandRegular;
			color : $greyBlack;
			flex  : 0 0 20%;
			width : 100%;
			height: 20%;

			text-align: center;
		}
	}

	&>*:not(.eventBtn) {
		@extend %notAllowed;
		pointer-events: none;
	}

	.eventBtn>.icon {
		@include icon($event, $greyLighten, 35%);
	}

	.blogBtn>.icon {
		@include icon($blog, $greyLighten, 35%);
	}

	.forumBtn>.icon {
		@include icon($forum, $greyLighten, 35%);
	}

	.websiteBtn>.icon {
		@include icon($website, $greyLighten, 35%);
	}

	.videocallBtn>.icon {
		@include icon($video, $greyLighten, 35%);
	}
}

.toolBar {
	flex  : 0 0 40%;
	width : 40%;
	height: 100%;

	display        : flex;
	flex-flow      : row nowrap;
	justify-content: flex-end;
}

.conf {
	@extend %toolBtn;
	@include icon($conf, $greyDark, 1.525rem);
}

.home {
	@extend %toolBtn;
	@include icon($home_baseline, $greyDark, 1.285rem);

	&.blueBtn {
		@include icon($home_baseline, $blue, 1.285rem);
	}
}

.message {
	@extend %toolBtn;
	@include icon($message, $greyDark, 1.125rem);
}

.notif {
	@extend %toolBtn;
	@include icon($notification, $greyDark, 1.29rem);
}

.profile {
	flex  : 0 0 20%;
	width : 20%;
	height: 100%;

	display        : flex;
	align-items    : center;
	justify-content: center;

	.profBtn {
		flex  : 0 0 30%;
		width : 30%;
		height: 100%;
		@include icon($profile, $greyDark, 100%);
		cursor: pointer;

		&:hover {
			background-color: #2FB1E5
		}
	}
}

.divProfile {
	position        : absolute;
	top             : 56px;
	right           : 10px;
	width           : 10rem;
	height          : 9rem;
	background-color: $white;
	border-radius   : 0.2rem;
	z-index         : 4;
	background-color: $white;
	box-shadow      : 2px 0 10px $grey;
	display         : flex;
	justify-content : center;
	align-content   : center;
	justify-items   : center;
	align-items     : center;
	flex-direction  : column;

	&::after {
		z-index   : 2;
		background: $white;
		content   : "";
		position  : relative;
		top       : -5.4rem;
		right     : 1rem;
		transform : rotate(45deg);
		width     : 1rem;
		height    : 1rem;
		@include breakpoint(fhscreenonly) {
			z-index   : 2;
			background: $white;
			content   : "";
			position  : relative;
			top       : -8.0rem;
			left      : 1.5rem;
			transform : rotate(45deg);
			width     : 1rem;
			height    : 1rem;
		}
		@include breakpoint(hdscreenonly2) {
			z-index   : 2;
			background: $white;
			content   : "";
			position  : relative;
			top       : -8.0rem;
			left      : 2.6rem;
			transform : rotate(45deg);
			width     : 1rem;
			height    : 1rem;
		}
	}

	button {
		width           : 80%;
		height          : 20%;
		border          : 1px solid transparent;
		color           : $white;
		background-color: $blue;
		border-radius   : 0.2rem;
		cursor          : pointer;
		margin          : 1rem;

		&:hover {
			border          : 1px solid $blueBtn;
			background-color: $white;
			color           : $blue
		}

	}


}