@import './variables.scss';


.footer {
    display        : flex;
    justify-content: flex-start;
    justify-items  : flex-start;
    align-content  : flex-start;
    align-items    : flex-start;
    width          : 94%;
    flex-direction : row;
    height         : 2rem;
    margin-bottom  : .2rem;
    margin-top     : .2rem;

    span {
        @extend %quicksandBold;
        margin-left: 0.5rem;
        color      : $grey;
    }


    .left {
        width          : 50%;
        display        : flex;
        flex-direction : row;
        justify-content: flex-start;
        justify-items  : flex-start;
        align-items    : center;
        align-content  : center;
        height         : auto;


        .iconBox {
            width          : 20%;
            display        : flex;
            flex-direction : row;
            justify-content: flex-start;
            justify-items  : flex-start;
            align-content  : flex-start;
            align-items    : flex-start;

            .view {
                width: 10%;
            }

            .comment {
                width: 10%;
            }

            .iconFavorite {
                cursor : pointer;
                display: block;
                width  : 1.2rem;
                height : 1.2rem;
                @include icon($favorite_outline, $greyDark, 100%);
                position: relative;
                z-index : 2;

                &:hover {
                    @include icon($favorite_outline, $danger, 100%);
                }

                &.redLike {
                    @include icon($favorite_fill, $danger, 100%);
                }
            }

            .iconForum {
                cursor : pointer;
                display: block;
                width  : 1.2rem;
                height : 1.2rem;
                @include icon($question_answer, $greyDark, 100%);
                position: relative;
                z-index : 2;
            }
        }
    }
}
