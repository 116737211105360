@import './variables.scss';

main {
    width           : 100%;
    height          : 100%;
    background-color: $light;
    display         : flex;
    overflow        : hidden;

    .example::-webkit-scrollbar {
        display: block;
    }

    .example {
        -ms-overflow-style: block;
    }

    .infoEmpty {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        background: $light;
        z-index: 0;
        p {
            font-size: 20px;
            color: $blueUltraSoft;
            margin-bottom: 16px;
        }
        i {
            display: block;
            width: 96px;
            height: 96px;
            @include icon($storefront, $blueUltraSoft, 100%);
        }

        button{
            text-transform  : uppercase;
            color           : $white;
            background-color: $blueLighten;
            text-align      : center;
            cursor          : pointer;
            transition      : all .25s ease-in-out;
            padding         : .5rem 1rem;
            width           : 7rem;
            border          : none;
            border-radius   : 3px;
            opacity         : 1;
            transition      : all .25s ease-in-out;

            &:hover{
                background-color: $blueLighten;
            }

            &:active{
                background-color: $blueDark;
            }

            &.hidden{
                opacity   : 0;
                transition: all .25s ease-in-out;
            }
        }

    }

    .container {
        flex-grow: 1;
        height: 100%;
        padding: 0px 32px;
        .toolbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-height: 48px;
            margin-top: 16px;
            > button {

                @extend %quicksandRegular;
                color           : $dark;
                background-color: $white;
                border          : 1px solid $greyDarken3;
                border-radius   : .4rem;
                height          : auto;
                transition      : all .25s ease-in-out;
                text-transform  : uppercase;

                &:hover{
                    background-color: $white;
                    color           : $blueLighten;

                    &::before{
                        opacity: 0;
                    }

                }

                &:active{
                    background-color: $light2;
                    color           : $greyBlack;
                }
            }
        }

        &>.categoryDisabled{
            display: none;
            justify-content: flex-start;
            align-items: center;

            &.showMsg{
                display: flex;
            }

            span{
                @extend %quicksandMedium;
                font-size: 16px;
                font-weight: 500;
                color: $dark;
            }

            button{
                margin-left: 15px;
                padding: 2px;
                border: none;
                border-radius: 5px;
                background-color: $blueLighten;
                transition: background .200s ease-in-out;

                &:hover{
                    background-color: $blueDark;
                }

                i{
                    background-color: $white;
                }
            }

        }

        &>section {
            height: calc(100% - (48px + 16px*2));
            margin-bottom: 16px;
            position: relative;
            display              : grid;
            width                : 100%;
            grid-template-columns: repeat(4,24%);
            grid-gap             : 15px 0px;
            grid-auto-rows       : max-content;
            justify-content      : space-between;
            overflow             : hidden;
            overflow-y           : auto;
            margin-top           : 1rem;
            padding              : .5rem 0;
        
            &.hide {
                display: none;
            }

        }

        &>.noCard{
            height: 100%;
            margin-bottom: 16px;
            display: flex;
            width: 100%;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin-top: 1rem;
            padding: .5rem 0;

            p {
                font-size: 20px;
                color: $blueUltraSoft;
                margin-bottom: 16px;
            }
            i {
                display: block;
                width: 96px;
                height: 96px;
                @include icon($storefront, $blueUltraSoft, 100%);
            }

        }
    }

    .addIcon{
        display: block;
        width: 24px;
        height: 24px;
        @include icon($add, $dark, 100%);
    }

    .editIcon{
        display: block;
        width: 24px;
        height: 24px;
        @include icon($blog, $dark, 100%);
    }

}

// style Modal Form

.containerModal{

    .overflow{
        width: 100%;
        height: 0;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(black, 0.3);
        z-index: 8;
        opacity: 0;
    }

    &.show{

        &.overflowActive{

            .overflow{
                height: 100%;
                opacity: 1;
                transition: opacity .75s ease;
            }
    
        }

        .contentForm{
            right      : 0;
            transition : right .75s ease-in-out;
        }

        &.centerModal{
            display: flex;
        }

    }

    &.new{

        .overflow{
            height: 100%;
            opacity: 1;
            transition: opacity .75s ease;
        }

    }

    .contentForm {
        display			: flex;
        flex-flow		: column wrap;
        position        : absolute;
        color           : $greyBlack;
        width           : 25rem;
        top             : 40%;
        transform       : translateY(-50%);
        right           : -25rem;
        border          : solid 1px $greyDarken3;
        background-color: $white;
        box-shadow      : 0 0 4px 0;
        transition      : right .75s ease-in-out;
        padding         : 1.5rem;
        z-index: 10;
    
        hr{
            width: 100%;
        }
    
        .contentHalf{
            display        : flex;
            flex-flow      : row nowrap;
            justify-content: space-between;
            align-items    : center;
    
            .contentInput{
                width: 48%;
            }
        }
        
        .btnContent{
    
            display        : flex;
            flex-flow      : row nowrap;
            justify-content: space-between;
            align-items    : center;
            padding-top    : 1rem;
    
            button{
                text-transform  : uppercase;
                color           : $dark;
                text-align      : center;
                cursor          : pointer;
                background-color: $greyLight3;
                transition      : all .25s ease-in-out;
                padding         : .25rem .50rem;
                width           : 6rem;
                min-height      : initial;
                border          : none;
                font-size       : 14px;
                border-radius   : 3px;
                box-sizing      : border-box;
    
                &:hover{
                    background-color: $greyLight2;
                }
    
                &:first-child{
    
                    color           : $white;
                    background-color: $blueLight;
        
                    &:hover{
                        background-color: $blueDark;
                    }
    
                }
    
            }
    
        }
    
        .contentInput{
            width  : 100%;
            display: flex;
            flex-flow: column wrap;
            margin-bottom: 1.25rem;
    
            &>label{
                margin-bottom: 10px;
            }

            &:not(.checkbox)>label{
                width: 100%;
                font-size: 14px;
            }

        }
    
        input, textarea, select{
            @extend %quicksandRegular;
            border          : 1px solid $greyDarken3;
            border-radius   : 3px;
            background-color: $white;
            color           : $dark;
            width           : 100%;
            outline         : none;
            padding         : .25rem .5rem;
    
            &.hideInput{
                position        : absolute;
                width           : 0;
                height          : 0;
                padding         : 0;
                margin          : 0;
                top             : 0;
                left            : 0;
                pointer-events  : none;
                border          : none;
                background-color: transparent;
            }
    
        }

        .isTitle{
    
            margin-bottom: 1.75rem;

            input{

                @extend %quicksandMedium;
                color    : $blueDark;
                padding  : 0;
                border   : none;
                font-size: 1.25rem;
    
                &::placeholder{
                    color: $blueDark;
                }
            }

            h3{

                @extend %quicksandMedium;
                color    : $dark;
                padding  : 0;
                font-size: 1.25rem;
                
            }

            &::after{
                content: " ";
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0;
                top: 4rem;
                background-color: $greyLight3;
            }

        }
    
        .contentOptions{

            flex-direction: row;

            input, select{
                border-bottom-right-radius : 0px;
                border-top-right-radius    : 0px;
            }

            >:last-child{
                border-left: 0;
                border-bottom-right-radius : 3px;
                border-top-right-radius    : 3px;
            }

            // input:last-child, textarea:last-child, select:last-child, .btnInputAction:last-child{
            // }

        }
    
        textarea{
            height: 10rem;
            resize: none;
        }
    
        ::placeholder{
            color: $greyBlack;
        }
    
        .checkbox{
    
            div{
                width: 100%;
                display: flex;
            }
    
            label{
                display : inline-block;
                position: relative;
                cursor  : pointer;
                margin-bottom: initial;
            }
    
            label:before {
                content         : '';
                position        : absolute;
                width           : 15px;
                height          : 15px;
                left            : 0;
                background-color: $white;
                border          : 2px solid $success;
                border-radius   : 3px;
            }
            
            input[type=checkbox]{
                display: none;
            }
                
            span{
                margin-left: 2rem;
            }
    
            input[type=checkbox]:checked+label:before{
                content    : "\2713";
                line-height: 15px;
                font-size  : 25px;
                color      : $success;
            }
    
        }

        .btnInputAction{
            border: none;
            border: 1px solid $greyDarken3;
            border-left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            width: 28px;

            i{
                transition: background .25s ease-in-out;
            }

            &:hover{
                
                i{
                    background-color: $blueDark;
                }
                
            }
        }

        .imgContent{

            .imgUpload{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                background-color: $yellowCard;
                border: 1px solid $greyLight3;
                text-align: center;
                height: 100vh;
                max-height: 140px;
                position: relative;
                overflow: hidden;

                .iconImages{
                    width   : 11rem;
                    height  : 15rem;
                    position: absolute;
                    @include icon($image, $white, 100%);
                }

                img{
                    width: 50%;
                    height: auto;
                }

                .overflow{

                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;

                    label {
                        @extend %quicksandMedium;
                        color        : $blueLighten;
                        padding: 10px 15px;
                        width: auto;
                        font-size: 1rem;
                        background-color: white;
                        border-radius: 3px;
                        cursor: pointer;
                        position: absolute;
                        margin: auto;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
    
                    input[type=file]{
                        display: none;
                    }
                }

                &:hover {
                    .overflow {
                        opacity: 1;
                    }
                }

                &.uploader{
                    background-color: transparent;
                    i{
                        display: none;
                    }
                }

            }

        }
    
    }

    &.centerModal, &.centerRight{
        //position: fixed;
        top: 7%;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(.centerRight){

            .contentForm{
                right: initial;
                top: initial;
                left: initial;
                transform: initial;
            }
            
        }

        &:not(.centerModal){

            .contentForm{
                top: 4%;
                transform: initial;
            }
            
        }

    }

    &.centerModal{
        position: absolute;
        top: -7%;
        left: -256px;
        height: calc(100% + 7%);
        width: calc(100% + 256px);
        display: none;
        justify-content: center;
        align-items: center;
    }

    &.centerRight{

        .contentForm{
            top: 4%;
        }

    }

    .btnCloseModal{
        position: absolute;
        right: 25px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 28px;

        i{
            display: block;
            width: 24px;
            height: 24px;
            @include icon($close, $dark, 100%);
            transition: background .25s ease-in-out;
        }

        &:hover{
            
            i{
                background-color: $blueDark;
            }
            
        }

    }

}

// selector Level 

.selectorCategory{
    position: absolute;    
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 140px;
    align-items: center;
    width: 150px;

    &, span {
      transition: 200ms ease-out;
    }

    span{
        @extend %quicksandMedium;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
        font-weight: 500;
        background: $blueDark;
        color: $light2;
        border-radius: 5px;
        margin-right: 8px;
    }

    select{
        border: none;
        background-color: transparent;
        @extend %quicksandRegular;
        color: $dark;
        width: calc(100% - 40px);
        border: 0;
        background-color: transparent;
        color: #404040;
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid transparent;
        transition: 200ms ease-out;

        &:hover{
            border-color: $greyDarken3;
        }

        &:active{
            background-color: $white;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

}
