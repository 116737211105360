@import './variables.scss';

.filterTab {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  height: 48px;
  font-size: 16px;
  color: $grey;
  &, span {
    transition: 200ms ease-out;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 24px;
    background: $grey;
    color: white;
    border-radius: 2px;
    margin-right: 8px;
  }
  &.actived {
    color: $blue;
    span {
      background: $blue;
    }
  }
}
