@import './variables.scss';

.profile {
    background-color: $light2;
    width           : 100%;
    height          : 100%;
    display         : flex;
    flex-direction  : column;

    .row {
        width         : 100%;
        height        : 70%;
        display       : flex;
        flex-direction: row;


        .rightProfile {
            display        : flex;
            justify-items  : flex-end;
            justify-content: flex-end;
            align-content  : center;
            align-items    : center;
            width          : 60%;
            height         : 100%;
        }

        .leftProfile {
            display        : flex;
            justify-items  : center;
            justify-content: center;
            align-content  : center;
            align-items    : center;
            width          : 40%;
            height         : 100%;
        }
    }

    .footerProfile {
        display        : flex;
        justify-items  : center;
        justify-content: center;
        align-content  : center;
        align-items    : center;
        width          : 100%;
        height         : 15%;
    }
}
