@import './variables.scss';

.search {
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid;
  border-color: transparent;
  border-radius: 100px;
  transition: 200ms;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: rgba(black, 0);
    border-radius: 50%;
    min-width: 36px;
    height: 36px;
    position: relative;
    transition: 200ms ease-out;
    margin-right: 16px;
    i {
      display: block;
      width: 24px;
      height: 24px;
      @include icon($search, $greyDark, 100%);
      position: relative;
      z-index: 2;
    }
    &:hover {
      background: rgba(black, .15);
    }
  }
  input {
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    padding: 0 16px 0 5px;
    transition: 200ms;
    border-bottom: 1px solid transparent;
  }
  &.expander {
    width: 200px;

    input {
      border-color: $greyDark;
    }

    .btn{
      background: rgba(black, .15);
    }

  }
}
