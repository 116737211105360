@import './variables.scss';

.contentSubDescription{

    display: grid;
    padding-top: 1rem;
    grid-template-columns: 23% 25% 25% 15% 12%;
    justify-items: baseline;
    align-items: center;

    span{
        @extend %quicksandRegular;
        color: $dark;
        font-size: 14px;
        
        b{
            @extend %quicksandMedium;
            color: $dark;
            font-weight: 500;
        }
    }

    span:nth-child(5){
        justify-self: end;
    }
}