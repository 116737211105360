@import './variables.scss';
 
.loaderWrapper {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 30000;
  border: 2px solid $white;
  &.show{
    display: flex;
  }
  &.background {
    background-color: $light;
 
    &.transparent{
      background-color: rgba($color: $black, $alpha: 0.37);
    }
 
  }
 
  &.fixed{
    position: fixed;
  }
  .loader {
    border: 0px solid #ccc;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: inline-block;
    animation: rotate180 2s infinite;
    border: 10px solid $greyDarken3;
    border-top-color: nth($blueDark,1);
  }
 
}
@keyframes rotate180 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate45 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    opacity: 0.3;
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
 
@keyframes rotateXY {
  0% {
    transform: rotate(0deg);
  }
  50% {
    opacity: 0.8;
    transform: rotateY(180deg) rotateX(0deg);
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}
 
@keyframes translateRotateSquares {
  0%,
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    opacity: 0.3;
    transform: translate(40px, 40px) rotate(45deg);
  }
  50% {
    transform: translate(0px, 80px) rotate(0deg);
  }
  75% {
    transform: translate(-40px, 40px) rotate(45deg);
  }
}
 
@keyframes translateSquares {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    opacity: 0.3;
    transform: translate(0px, 5px);
  }
  75% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@keyframes wave {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    opacity: 0.3;
    transform: translate(-2px, 0px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
 
@keyframes wave2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    opacity: 0.3;
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
 
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
 
// style Loader Progress Bar
 
.LoaderContentBar{
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative; 
  background-color: $light2;
  border: 1px solid $greyDarken3;
  border-radius: 3px;
 
  svg{
    width: 100%;
    height: 30px;
    transition: width .5s ease-in-out;
    background-color: $light2;
    border: 1px solid $greyDarken3;
    border-radius: 3px;
 
    rect{
      fill:$green;
      transition: width .5s ease-in-out;
    }
 
  }
 
  .textLoading{
    position: absolute;
    color: $darkness;
    @extend %quicksandRegular;
  }
 
  &.done{
 
    .textLoading{
      color: $white;
      @extend %quicksandMedium;
    }
 
  }
 
  &.error{
 
    svg{
      background-color: $greyDarken3;
 
    }
 
    .textLoading{
      color: $darkness;
      @extend %quicksandMedium;
    }
 
    .btnResetContent{
      display: block;
    }
 
  }
 
  .btnResetContent{
 
    position: absolute;
    display: none;
    width: 30px;
    height: 30px;
    left: -35px;
 
    .iconReset{
 
      width: 100%;
      height: 100%;
 
    }
 
  }
 
}
 
// style Loader Progress Circle
 
.LoaderContentCircle{
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $black, $alpha: 0.37);
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
 
  &.show{
    display: flex;
  }
 
  .wrapperCircle{
 
    display: flex;
    width: 100px;
    height: 100px;
    border: 1px solid transparent;
    border-radius: 3px;
 
    &.backgroundCircle{
      background-color: $light2;
      border-color: $greyDarken3;
    }
 
  }
 
  .textError{
    @extend %quicksandMedium;
    color: $darkness;
  }
 
  svg{
    position: absolute;
    transition: all .5s ease-in-out;
 
    circle{
      fill: transparent;
      stroke-width: 12;
      stroke-dashoffset: 0;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      transition: all .5s ease-in-out;
 
      &:nth-child(1){
        stroke: $greyDarken3;
      }
 
      &:nth-child(2){
        stroke: $blueDark;
      }
    }
 
  }
 
  &.error{
 
    svg{
      display: none;
    }
 
    .textLoading{
      color: $darkness;
      @extend %quicksandMedium;
    }
 
    .btnResetContent{
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
 
  }
 
  .btnResetContent{
 
    display: none;
    width: 100%;
    height: 100%;
 
    .iconReset{
 
      width: 70%;
      height: 70%;
 
    }
 
  }
 
}
 
.iconReset{
 
  @include icon($refresh, $blueDark, 100%);
 
  &:active{
    background-color: $blueDark;
  }
 
  &:hover{
    background-color: $blueLighten;
    cursor: pointer;
  }
 
}