@import './variables.scss';

.eventsBar {
    width           : 100%;
    height          : 6.2rem;
    padding         : 0 2rem;
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    background-color: $white;

    .groupSearch {
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        align-content  : center;
        z-index        : 1;
        width          : 18rem;
        border         : solid 1px $light3;
        box-shadow     : none;
    }

    .search {
        padding-left    : 0.7rem;
        border          : none;
        border-right    : solid 1px $light3;
        background-color: $light;
        width           : 18rem;
        color           : $dark;

        &:focus{
            background-color: $white;
        }
        &:focus~.iconSearch{
            background-color: $blue;
        }
    }

    .iconSearch {
        mask-image        : url($search);
        -webkit-mask-image: url($search);
        background-color  : $greyLighten2;
        width             : 24px;
        height            : 24px;
        background-repeat : no-repeat;
        margin            : 0.2rem;
    }

    .iconArrowLeft {
        display : inline-block;
        width   : 20%;
        height  : 80%;
        position: relative;
        left    : -8%;
        @include icon($chevron_left, $blue, 20%);

        &:hover {
            @include icon($chevron_left, $blueDark, 20%);
        }
    }

    .iconBlueDark {
        background-color: $blueDarker;
    }

    .left {
        margin-left    : 3rem;
        width          : 70%;
        display        : flex;
        align-content  : center;
        justify-content: flex-start;
        align-items    : center;
        justify-items  : flex-start;
    }

    .new {
        display         : flex;
        align-content   : center;
        justify-content : center;
        align-items     : center;
        justify-items   : center;
        width           : 8rem;
        height          : 1.9rem;
        border          : none;
        color           : $white;
        background-color: $blueLighten;
        font-size       : 1.2rem;

        &:focus {
            background-color: $blueDark;
        }

        &:hover {
            box-shadow: 2px -2px 2px -2px $greyDark;
        }
    }

    h2 {
        display: flex;
        width  : 15%;
        height : 100%;

        justify-content: flex-start;
        align-items    : center;

        .icon {
            display: inline-block;
            width  : 31%;
            height : 100%;
            @include icon($event, $blue, 60%);
        }

        .title {
            @extend %quicksandRegular;
            color      : $blue;
            margin-left: 1rem;
        }
    }
}

.eventContainer {
    width           : 100%;
    height          : 50% auto;
    background-color: $light;

    &.complete {
        height: 100%;
    }
}

.contentCards {
    width          : 95%;
    height         : 100%;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-between;
    align-items    : flex-start;
    align-content  : flex-start;
}

.cards {
    cursor         : pointer;
    color          : $dark;
    display        : flex;
    justify-content: space-between;
    align-content  : center;
    margin-top     : 2rem;
    margin-left    : 3.5rem;
    width          : 22rem;
    height         : 6rem;
    background     : $white;
    border         : solid 0.05rem $light3;
    border-radius  : 0.01;

    &:focus {
        color: $blueDark;
    }

    .detailsCard {
        width          : 60%;
        height         : 100%;
        display        : flex;
        align-content  : flex-start;
        align-items    : flex-start;
        justify-content: flex-start;
        justify-items  : flex-start;
        flex-direction : column;
        margin-left    : 5%;
        margin-top     : 2%;

        .title {
            font-weight: 600;
            color      : $dark;
            font-size  : 0.9rem;

            &:hover {
                color: $blue;
            }
        }

        .date {
            color     : $dark;
            font-size : 0.8rem;
            margin-top: -1rem;
        }

        .location {
            color    : $dark;
            font-size: 0.8rem;
        }

        .fontBlue {
            color: $blueDark;
        }
    }

    .cardFocus {
        border: solid 0.02rem $greyDarken3;
    }

    .img {
        position: relative;
        width   : 45%;
        height  : 100%;
    }

    .red {
        background-color: $redCard;
    }

    .blue {
        background-color: $blueCard;
    }

    .green {
        background-color: $greenCard;
    }

    .yellow {
        background-color: $yellowCard;
    }
}


.see {
    width          : 100%;
    height         : 1.5rem;
    text-align     : right;
    display        : flex;
    flex-direction : row;
    position       : relative;
    justify-content: flex-end;
    align-content  : center;
    justify-items  : flex-end;
    align-items    : center;

    span {
        color: $blueDark;
    }

    a {
        color: $blueDark;
    }

    .iconArrowRight {
        margin            : 10px 0px 10px 0px;
        padding           : 5px 5px 5px 15px;
        mask-image        : url($chevron_right);
        -webkit-mask-image: url($chevron_right);
        background-color  : $light;
        width             : 24px;
        height            : 24px;
        background-repeat : no-repeat;
        font-weight       : 600;

        &:hover {
            background-color: $blueDarker;

        }
    }

    .iconBlueDark {
        background-color: $blueDarker;
    }
}

.line {
    margin-left  : 2%;
    width        : 96%;
    padding-top  : 2.5rem;
    border-bottom: solid 0.04rem $light3;
    text-align   : top;

    span {
        position   : relative;
        color      : $greyDarken2;
        bottom     : 0.5rem;
        left       : 0.8rem;
        font-weight: 600;
        font-size  : 0.8rem;
    }

}

.hide {
    display: none;
}
