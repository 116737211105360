@import './variables.scss';

.scroll {
    position        : fixed;
    top             : 90%;
    left            : 90%;
    z-index         : 5;
    width           : 3rem;
    height          : 3rem;
    border-radius   : 50%;
    background-color: $white;
    display         : flex;
    justify-content : center;
    justify-items   : center;
    align-items     : center;
    align-content   : center;
    border          : none;
    box-shadow      : $shadow;

    .iconUp {
        cursor : pointer;
        display: block;
        width  : 2.5rem;
        height : 2.5rem;
        @include icon($expand_less, $dark2, 100%);
        position: relative;
        z-index : 6;

        &:hover {
            @include icon($expand_less, $blueDark, 100%);
        }
    }
}
