@import './variables.scss';

.footer {
    width              : 90%;
    height             : 5%;
    background-color   : $greyColor;
    position           : fixed;
    top                : 80%;
    padding            : 1rem 2rem 1rem 2rem;
    transition-duration: 1s, .5s;
    opacity            : 5;
    transition-property: height, opacity;


    p {
        opacity: 0;
        color  : $dark;
    }

    h3 {
        opacity: 0;
        color  : $dark;
    }

    &.animation {
        opacity            : 1;
        transition-property: height, opacity;
        transition-duration: 1s, .5s;
        height             : 15%;


        p {
            opacity            : 1;
            color              : $dark;
            transition-property: opacity;
            transition-duration: 1s, .5s;
        }

        h3 {
            opacity            : 1;
            color              : $dark;
            transition-property: opacity;
            transition-duration: 1s, .5s;
        }
    }
}
