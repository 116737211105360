@import './variables.scss';

.error {
    display  : flex;
    position : absolute;
    top      : -2rem;
    width    : 100%;
    font-size: .8em;
    color    : $danger;
    justify-content: center;
}