@import './variables.scss';

.cfpDashboard{
	display: flex;
	width: 100%;
	height: 100%;

	justify-content: center;
	align-items: center;

	.noForms{
		display: flex;
		width: 60%;
		max-width: 400px;
		height: 60%;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		.text{
			flex: 0 0 10%;
			width: 100%;
			height: 10%;
			text-align: center;
			font-size: .9rem;
			font-size: 1rem;
			color: $grayBlue;

			@extend %quicksandRegular;
		}

		.icon{
			flex: 0 0 40%;
			width: 100%;
			height: 40%;
			@include icon($how_to_vote,$blueUltraSoftly,20%);
		}

		button{
			padding: .3rem 4rem;
			border: none;
			color: $white;
			background-color: $blue;
			font-size: 1rem;
			cursor: pointer;

			a{
				color: $white;
			}
		}
	}
}