@import './variables.scss';

.leftBar {
  min-width: 256px;
  height: 100%;
  background: $light;
  h6 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    color: white;
    background: $blueLight;
    border-radius: 0 30px 30px 0px;
    font-size: 18px;
    font-weight: 500;
    margin: 16px 0px 16px 0px;
  }
  section {
    width: 100%;
    height: calc(100% - (48px + 48px));
    overflow: hidden;
    padding-bottom: 16px;
    &:hover {
      overflow: auto;
    }
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 50px;
      //background: rgba(black, .10);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgba(black, .05);
      &:active {
        background: rgba(black, .1);
      }
    }
    hr {
      width: calc(100%  - 48px);
      margin: 16px 24px;
      border-color: rgba(black, .10);
    }
  }
}
