@import './variables.scss';

.userDashboard {
    width           : 100%;
    height          : 100%;
    background-color: $light2;
}

.container {
    width           : 100%;
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    justify-items   : space-between;
    align-items     : flex-start;
    align-content   : flex-start;
    overflow-y      : scroll;
    overflow-x      : hidden;
    background-color: $light2;
    height          : 90%;
}
