@import './variables.scss';

.eventContainer {
    width           : 100%;
    height          : 50%;
    background-color: $light;

    &.complete {
        height    : 100%;
        overflow-y: scroll;

    }
}


.contentCards {
    width          : 95%;
    height         : 100%;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: flex-start;
    align-items    : flex-start;
    align-content  : flex-start;
    justify-items  : flex-start;
    margin-left    : 3%;

}

.see {
    width          : 95%;
    margin-top     : 1rem;
    height         : 2rem;
    text-align     : right;
    display        : flex;
    flex-direction : row;
    position       : relative;
    justify-content: flex-end;
    align-content  : center;
    justify-items  : flex-end;
    align-items    : center;

    &:hover {
        .iconArrowRight {
            background-color: $blueDarker;
        }
    }

    span {
        color: $blueDark;
    }

    a {
        color: $blueDark;
    }

    .iconArrowRight {
        margin            : 10px 0px 10px 0px;
        padding           : 5px 5px 5px 15px;
        mask-image        : url($chevron_right);
        -webkit-mask-image: url($chevron_right);
        background-color  : $light;
        width             : 24px;
        height            : 24px;
        background-repeat : no-repeat;
        font-weight       : 600;

        &:hover {
            background-color: $blueDarker;

        }
    }

}

.line {
    margin-left  : 0%;
    width        : 96%;
    padding-top  : 2.5rem;
    border-bottom: solid 0.04rem $light3;
    text-align   : top;

    span {
        position   : relative;
        color      : $greyDarken2;
        bottom     : 0.5rem;
        left       : 0rem;
        font-weight: 600;
        font-size  : 0.8rem;
    }

}

.hide {
    display: none;
}

.red {
	background-color: $redCard;
}

.blue {
	background-color: $blueCard;
}

.green {
	background-color: $greenCard;
}

.yellow {
	background-color: $yellowCard;
}
