@import './variables.scss';

.cardTicket{

    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0.9375rem;
    background-color: $white;
    border: 1px solid $greyDarken3;
    border-radius: 3px;
    transition: all .15s ease-in-out;

    &.selected,
    &:hover{
        box-shadow: 0px 0px 4px 0px;
    }

    &.selected{
        border-color: $grey;
    }

    &.hidden{
        display: none
    }

    &>header{

        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 1rem;

        &> h3{
            @extend %quicksandBold;
            color: $dark;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: calc(100% - 1.6rem);
        }

        &> span{
            @extend %quicksandMedium;
            display: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            text-transform: uppercase;
            border-radius: 12px;
            width: 5rem;
            height: 1.563rem;
            font-size: .75rem;
            right: 3rem;
            background-color: $greyLight3;
            color: $dark;
        }

    }

    >section{

        width: 100%;
        height: 100%;
        min-height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
        img{
            max-height: 110px;
            max-width: 200px;
        }
    }

}