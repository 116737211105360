@import './variables.scss';

.rightAside {
    width          : 25%;
    height         : 100%;
    display        : flex;
    justify-content: center;
    justify-items  : center;
    align-items    : flex-start;
    align-content  : flex-start;
    margin-top     : 2rem;

    ::-webkit-scrollbar {
        display: none;
    }

}
