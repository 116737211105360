@import './variables.scss';

.boxMessage {
    border          : solid $light3 0.01rem;
    padding         : 20px;
    width           : 75%;
    background-color: $white;
    display         : flex;
    flex-direction  : column;
    border-radius   : 0.1rem;
    min-height      : 10rem;
    max-height      : 100%;
    height          : auto;


    h3 {
        font-size  : 1.4rem;
        margin-left: .4rem;
        @extend %quicksandMedium;
        color: $dark;

        &:hover {
            color: $blueDark;
        }
    }

    .iconGroup {
        cursor : pointer;
        display: block;
        width  : 2.5rem;
        height : 2.5rem;
        @include icon($group, $greyDark, 80%);
        position: relative;
        z-index : 2;

        &:hover {
            @include icon($group, $blueDark, 80%);
        }
    }

}

.row {
    display        : flex;
    flex-direction : row;
    width          : 100%;
    height         : 10%;
    justify-content: flex-start;
    justify-items  : flex-start;
    align-items    : center;
    align-content  : center;
    word-wrap      : break-word;
    overflow       : hidden;
    text-overflow  : ellipsis;

    &.right {
        display        : flex;
        justify-content: flex-end;
        justify-items  : flex-end;
        align-items    : center;
        align-content  : center;
        margin-bottom  : 1.5rem;
        height         : 1.3rem;

        .iconForum {
            cursor: pointer;
            width : 2.5rem;
            height: 2.5rem;
            @include icon($forum, $greyDark, 50%);
            z-index: 2;

            &:hover {
                @include icon($forum, $blueDark, 50%);
            }
        }
    }
}

.forum {
    margin-top: .5rem;
    height    : auto;

    .boxForum {
        display       : flex;
        flex-direction: column;

        .title {
            font-size    : 1.2rem;
            width        : 90%;
            color        : $dark;
            overflow     : hidden;
            white-space  : nowrap;
            text-overflow: ellipsis;
            //@extend %quicksandMedium;
            font-weight  : 500;
            word-wrap    : break-word;
            overflow     : hidden;
            text-overflow: ellipsis;
        }

        .by {
            padding-top: .3rem;
        }
    }
}

.footer {
    @extend %row;
    justify-content: center;
    justify-items  : center;
    align-items    : flex-end;
    align-content  : flex-end;

    .iconExpandMore {
        cursor : pointer;
        display: block;
        width  : 2rem;
        height : 2rem;
        @include icon($expand_more, $greyBlack, 100%);
        z-index: 2;


        &:hover {
            @include icon($expand_more, $blueDark, 100%);
        }
    }

    .iconExpandLess {
        cursor : pointer;
        display: block;
        width  : 2rem;
        height : 2rem;
        @include icon($expand_less, $greyBlack, 100%);
        z-index: 2;


        &:hover {
            @include icon($expand_less, $blueDark, 100%);
        }
    }
}
