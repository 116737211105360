@import './variables.scss';



///INIT FORMS///
.inputSelect {
    background: $white;
    max-height: 175px;
    height    : auto;
    text-align: left;
    z-index   : 4;
    width     : 44.7%;
    border    : solid $greyDarken3 2px;
    border-top: none;
    margin-top: 3.5rem;
    z-index   : 1;
    position  : absolute;
    display   : block;
    overflow  : hidden;
    @include breakpoint(hdscreenonly2) {
        width     : 45.3%;
    }
}

.divInputIcon {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-content  : flex-start;
    z-index        : 1;

    .divIcons {
        display        : flex;
        justify-items  : center;
        align-items    : center;
        align-content  : center;
        justify-content: center;
        width          : 2.4rem;
        height         : 2.2rem;
        border-left    : solid $greyDarken3 2px;
        border-top     : solid $greyDarken3 2px;
        border-bottom  : solid $greyDarken3 2px;
        border-right   : solid $greyDarken3 2px;
        margin-left    : -.2rem;

        .iconExpandM {
            cursor: pointer;
            width : 2rem;
            height: 2rem;
            @include icon($expand_more, $greyDark, 100%);
            z-index: 2;
        }

        .iconExpandL {
            cursor: pointer;
            width : 2rem;
            height: 2rem;
            @include icon($expand_less, $greyDark, 100%);
            z-index: 2;
        }
    }

    .inputIcon {
        width        : 86%;
        height       : 2.2rem;
        padding-left : .5rem;
        border-left  : solid $greyDarken3 2px;
        border-top   : solid $greyDarken3 2px;
        border-bottom: solid $greyDarken3 2px;
        border-right : none;
        border-radius: 2px;
        @extend %quicksandMedium;
        color    : $dark;
        font-size: 1rem;
    }
}

.gender {
    height: 80px;
}

.list {
    cursor         : pointer;
    display        : flex;
    flex-direction : column;
    justify-content: left;
    align-content  : flex-start;
    padding-left   : 1rem;
    padding-bottom : 5px;
    z-index        : 4;

    &:hover {
        background-color: $greyLight2;
    }
}

//ICONS//

.iconPhone {
    cursor : pointer;
    display: block;
    width  : 1.8rem;
    height : 1.8rem;
    @include icon($phone, $greyDark, 100%);
    position: relative;
    z-index : 2;
}

.iconHome {
    cursor : pointer;
    display: block;
    width  : 1.8rem;
    height : 1.8rem;
    @include icon($home, $greyDark, 100%);
    position: relative;
    z-index : 2;
}

.iconAccount {
    cursor : pointer;
    display: block;
    width  : 1.8rem;
    height : 1.8rem;
    @include icon($account_circle, $greyDark, 100%);
    position: relative;
    z-index : 2;
}

.iconLock {
    cursor : pointer;
    display: block;
    width  : 1.8rem;
    height : 1.8rem;
    @include icon($lock, $greyDark, 100%);
    position: relative;
    z-index : 2;
}

.iconPublic {
    cursor : pointer;
    display: block;
    width  : 1.8rem;
    height : 1.8rem;
    @include icon($public, $greyDark, 100%);
    position: relative;
    z-index : 2;
}

.iconEmail {
    cursor : pointer;
    display: block;
    width  : 1.8rem;
    height : 1.8rem;
    @include icon($email, $greyDark, 100%);
    position: relative;
    z-index : 2;
}

.iconPerson {
    cursor : pointer;
    display: block;
    width  : 1.8rem;
    height : 1.8rem;
    @include icon($person, $greyDark, 100%);
    position: relative;
    z-index : 2;
}
