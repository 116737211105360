@import './variables.scss';

main {
    width           : 100%;
    height          : 100%;
    background-color: $light;
    display         : flex;
    overflow        : hidden;

    .example::-webkit-scrollbar {
        display: block;
    }

    .example {
        -ms-overflow-style: block;
    }

    .infoEmpty {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        background: $light;
        z-index: 0;
        p {
            font-size: 20px;
            color: $blueUltraSoft;
            margin-bottom: 16px;
        }
        i {
            display: block;
            width: 96px;
            height: 96px;
            @include icon($amp_stories, $blueUltraSoft, 100%);
        }

        button{
            text-transform  : uppercase;
            color           : $white;
            background-color: $blueLighten;
            text-align      : center;
            cursor          : pointer;
            transition      : all .25s ease-in-out;
            padding         : .5rem 1rem;
            width           : 7rem;
            border          : none;
            border-radius   : 3px;
            opacity         : 1;
            transition      : all .25s ease-in-out;

            &:hover{
                background-color: $blueLighten;
            }

            &:active{
                background-color: $blueDark;
            }

            &.hidden{
                opacity   : 0;
                transition: all .25s ease-in-out;
            }
        }

    }

    .container {
        flex-grow: 1;
        height: 100%;
        padding: 0px 32px;
        .toolbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-height: 48px;
            margin-top: 16px;
            > button {

                @extend %quicksandRegular;
                color           : $dark;
                background-color: $white;
                border          : 1px solid $greyDarken3;
                border-radius   : .4rem;
                height          : auto;
                transition      : all .25s ease-in-out;
                text-transform  : uppercase;

                &:hover{
                    background-color: $white;
                    color           : $blueLighten;

                    &::before{
                        opacity: 0;
                    }

                }

                &:active{
                    background-color: $light2;
                    color           : $greyBlack;
                }
            }
        }

        &>section {
            height: calc(100% - (48px + 16px*2));
            margin-bottom: 16px;
            position: relative;
            display              : grid;
            width                : 100%;
            grid-template-columns: 45% 45%;
            grid-gap             : 20px 0px;
            grid-auto-rows       : max-content;
            justify-content      : space-evenly;
            overflow             : hidden;
            overflow-y           : auto;
            margin-top           : 1rem;
            padding              : .5rem 0;
        
            &.hide {
                display: none;
            }

        }
    }

}
