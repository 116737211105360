@import './variables.scss';

.eventContainer {
    width           : 100%;
    background-color: $light;
}

.contentCards {
    // height: 100%;
    // display: grid;
    // margin: 0% 3%;
    // grid-column-gap: 1rem;
    // grid-row-gap: 1rem;
    // padding-top: 1rem;
    // grid-template-columns: 33% 33% 33%;
    display: flex;
    align-content: flex-start;
    overflow-y: scroll;
    flex-flow: row wrap;
    overflow-x: hidden;
    margin: 1rem 4rem 0;
}

.see {
    margin-top     : 1rem;
    margin         : 0% 3%;
    height         : 2rem;
    text-align     : right;
    display        : flex;
    flex-direction : row;
    position       : relative;
    justify-content: flex-end;
    align-content  : center;
    justify-items  : flex-end;
    align-items    : center;

    &.hide{
        display: none;
    }

    &:hover {
        .iconArrowRight {
            background-color: $blueDarker;
        }
    }

    span {
        color: $blueDark;
    }

    a {
        color: $blueDark;
    }

    .iconArrowRight {
        margin            : 10px 0px 10px 0px;
        padding           : 5px 5px 5px 15px;
        mask-image        : url($chevron_right);
        -webkit-mask-image: url($chevron_right);
        background-color  : $light;
        width             : 24px;
        height            : 24px;
        background-repeat : no-repeat;
        font-weight       : 600;

        &:hover {
            background-color: $blueDarker;

        }
    }

}

.line {
    margin       : 0% 3%;
    padding-top  : 2.5rem;
    border-bottom: solid 0.04rem $light3;
    text-align   : top;

    span {
        position   : relative;
        color      : $greyDarken2;
        bottom     : 0.5rem;
        left       : 0rem;
        font-weight: 600;
        font-size  : 0.8rem;
    }

}
