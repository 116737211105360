@import './variables.scss';

a {
	text-decoration: none;
}
.contentCardEvent {
	width : 100%;
	height: 100%;
	padding-bottom: 3rem;
	::-webkit-scrollbar {
		display: none;
	}
}

.eventContainer {
	width           : 100%;
	height          : 100%;
	overflow-y      : scroll;
	padding-bottom: 3rem;
	background-color: $light;

	&.hide {
		display: none;
	}

}

.noEvents {
	width          : 100%;
	height         : 100%;
	display        : flex;
	justify-content: center;
	align-items    : center;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	p {
		font-size: 20px;
		color: $blueUltraSoft;
		margin-bottom: 16px;
	}
	i {
		display: block;
		width: 96px;
		height: 96px;
		@include icon($event, $blueUltraSoft, 100%);
	}

	.btn{
		text-transform  : uppercase;
		color           : $white;
		background-color: $blueLighten;
		text-align      : center;
		cursor          : pointer;
		transition      : all .25s ease-in-out;
		padding         : .5rem 1rem;
		width           : 10rem;
		border          : none;
		border-radius   : 3px;
		opacity         : 1;
		transition      : all .25s ease-in-out;

		&:hover{
			background-color: $blueLighten;
		}

		&:active{
			background-color: $blueDark;
		}

		&.hidden{
			opacity   : 0;
			transition: all .25s ease-in-out;
		}
	}
}
