@import './variables.scss';

.cardTicket{

    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0.9375rem;
    padding-left: calc(10px + 0.9375rem);
    background-color: $white;
    border: 1px solid $greyDarken3;
    border-radius: 3px;
    transition: all .15s ease-in-out;

    &.selected,
    &:hover{
        box-shadow: 0px 0px 4px 0px;
    }

    &.selected{
        border-color: $grey;
    }

    &.hidden{
        display: none
    }

    &.inactive{

        &::before{
            background-color: $greyDarken3;
        }

        &>header{

            h3{
                @extend %quicksandMedium;
                color: $greyBlack;
            }

            span{
                display: flex;
            }

        }

    }


    &:before{
        content: "";
        position: absolute;
        width: 10px;
        height: 100%;
        top: 0;
        left: 0;
    }

    &>header{

        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 1rem;

        &> h3{
            @extend %quicksandBold;
            color: $dark;
        }

        &> span{
            @extend %quicksandMedium;
            display: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            text-transform: uppercase;
            border-radius: 12px;
            width: 5rem;
            height: 1.563rem;
            font-size: .75rem;
            right: 3rem;
            background-color: $greyLight3;
            color: $dark;
        }

    }

    p{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        @extend %quicksandRegular;
        color: $dark;
        max-height: 3rem;
    }

    &:nth-child(4n-3):not(.inactive){
        &::before{
            background-color: $green;
        }

        .emphasis{
            color: $green;
        }

    }

    &:nth-child(4n-2):not(.inactive){

        &::before{
            background-color: $blueLighten;
        }

        .emphasis{
            color: $blueLighten;
        }

    }

    &:nth-child(4n-1):not(.inactive){

        &::before{
            background-color: $yellowCard;
        }

        .emphasis{
            color: $yellowCard;
        }

    }

    &:nth-child(4n):not(.inactive){

        &::before{
            background-color: $dark2;
        }

        .emphasis{
            color: $dark2;
        }

    }

    &:nth-child(4n-3):not(.inactive),
    &:nth-child(4n-2):not(.inactive),
    &:nth-child(4n-1):not(.inactive),
    &:nth-child(4n):not(.inactive){

        .contentInfoStock span:nth-child(3) > b.ticketOut{
            color: $danger;
        }

    }

    .priceTicket{
        @extend %quicksandMedium;
        color: $dark2;
    }

}