@import './variables.scss';

.boxCenter {
    margin-top    : 1rem;
    border-radius : 0.1rem;
    width         : 95%;
    height        : auto;
    border        : solid 0.01rem $light3;
    z-index       : 3;
    flex-direction: column;
    background    : $white;
    display       : flex;
    justify-items : center;
    align-items   : center;
    padding-bottom: 1rem;
    padding-top   : 1rem;

    .desc {
        width         : 95%;
        height        : auto;
        padding-top   : .2rem;
        padding-bottom: 1rem;
        text-align    : justify;
        font-size     : 1.1rem;

        @include breakpoint(hdscreenonly2) {
            p {
                font-size: 1rem;
            }
        }
    }

    .left {
        width          : 46%;
        display        : flex;
        flex-direction : row;
        justify-content: flex-start;
        justify-items  : flex-start;
        align-items    : center;
        align-content  : center;
        height         : auto;
    }

    .right {
        width          : 46%;
        display        : flex;
        flex-direction : row;
        justify-content: flex-end;
        justify-items  : flex-end;
        align-items    : center;
        align-content  : center;
        height         : auto;
    }

    .column {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        justify-items  : center;
        align-items    : flex-start;
        align-content  : flex-start;
    }

    .content {
        display        : flex;
        justify-items  : center;
        justify-content: center;
        align-items    : center;
        align-content  : center;
        max-width      : 95%;
        height         : auto;

        &.imgMore {
            flex-wrap     : wrap;
            flex-direction: row;
        }

        .img1 {
            cursor    : pointer;
            min-width : 50%;
            max-width : 100%;
            width     : auto;
            min-height: 70%;
            max-height: 98%;
            padding   : 0.1rem;
        }

        .img2 {
            cursor    : pointer;
            min-width : 45%;
            width     : 50%;
            min-height: 20rem;
            max-height: 20rem;
            padding   : 0.1rem;
        }

        .img3 {
            cursor    : pointer;
            min-width : 33%;
            width     : auto;
            min-height: 10rem;
            max-height: 20rem;
            padding   : 0.1rem;
        }

        .img4 {
            cursor    : pointer;
            min-width : 40%;
            max-width : 50%;
            min-height: 10rem;
            max-height: 20rem;
            padding   : 0.1rem;
        }

        .img5 {
            cursor    : pointer;
            min-width : 25%;
            max-width : 30%;
            width     : auto;
            min-height: 10rem;
            max-height: 20rem;
            padding   : 0.1rem;
        }

        .video {
            cursor   : pointer;
            width    : 100%;
            height   : auto;
            min-width: 600px;
        }

        .text {
            max-width : 70%;
            max-height: 100%;
        }
    }

}

.postModal {
    width          : 100%;
    height         : 70%;
    display        : flex;
    flex-direction : column;
    justify-items  : space-between;
    justify-content: space-between;
    align-items    : center;
    align-content  : center;
    @extend %quicksandMedium;

    @include breakpoint(mobileonly) {

        height: auto;
    }

    @include breakpoint(tabletonly) {
        height: auto;
    }

    h2 {
        @extend %quicksandBold;
        color: $dark2;
    }

    span {
        font-size: 1.2rem;
        color    : $dark;

    }

    button {
        text-align      : center;
        cursor          : pointer;
        width           : 50%;
        height          : 2.2rem;
        border          : none;
        color           : $white;
        background-color: $blueLighten;
        font-size       : 1.2rem;
        @extend %quicksandBold;

        @include breakpoint(mobileonly) {
            font-size: .7rem;
            height   : auto;
        }

        @include breakpoint(tabletonly) {
            font-size: .9rem;
            height   : auto;
        }

        &:hover {
            background-color: $blueDark;
        }
    }
}

.modalDetailsPost {
    display       : flex;
    flex-direction: row;
    width         : 100%;
    height        : 100%;
    z-index       : 99999;
}

.headerView {
    justify-content: flex-start;
    justify-items  : flex-start;
    align-content  : center;
    align-items    : center;
    width          : 100%;
    display        : flex;
    flex-direction : row;
    height         : 5.3rem;

    .columnView {
        @extend %column;
        max-width: 70%;

        .name {
            margin-left: 0.5rem;
            font-size  : 1.2rem;
            color      : $dark2;
            width      : 100%;
            @extend %quicksandBold;
        }

        .date {
            margin-left: 0.5rem;
            font-size  : 0.9rem;
            max-width  : 100%;
        }
    }


    .user {
        color      : $blue;
        font-size  : 1rem;
        margin-left: 0.5rem;
    }

    img {
        border-radius: 50%;
        width        : 3rem;
        height       : 3rem;
    }

    .divIcon {
        width          : 60%;
        display        : flex;
        justify-content: flex-end;
        justify-items  : flex-end;
        align-content  : center;
        align-items    : center;

        .iconOption {
            cursor : pointer;
            display: block;
            width  : 1.8rem;
            height : 1.8rem;
            @include icon($more_vert, $greyDark, 100%);
            position: relative;
            z-index : 2;
        }
    }

    .iconUser {
        cursor : pointer;
        display: block;
        width  : 2.5rem;
        height : 2.5rem;
        @include icon($profile, $greyDark, 100%);
        position: relative;
        z-index : 2;
    }
}

.contentLeft {
    @extend %column;
    width           : 50%;
    background-color: $light2;

    &.media {
        background-color: $dark;
    }

    .iconBox {
        width          : 95%;
        position       : relative;
        margin-top     : 54%;
        bottom         : 0%;
        display        : flex;
        flex-direction : row;
        justify-content: flex-end;
        justify-items  : center;
        align-content  : space-around;
        align-items    : center;
    }

    .leftView {
        width           : 100%;
        height          : 100%;
        background-color: $light2;
        position        : relative;
        display         : flex;
        justify-content : center;
        align-content   : center;
        justify-items   : center;
        align-items     : center;

        &.media {
            background-color: $dark;
        }

        .videoView {
            max-width : 100%;
            max-height: 100%;
            min-width : 90%;
            min-height: 90%;
        }

        .imgView {
            max-width : 100%;
            max-height: 100%;
        }
    }

}

.right {
    width           : 50%;
    height          : 100;
    background-color: $white;
    position        : relative;
    padding         : 1rem;


    .column {
        @extend %row;
        justify-items  : space-around;
        align-items    : space-around;
        justify-content: space-around;
        align-content  : space-around;
    }
}

.contentComments {
    width          : 100%;
    height         : auto;
    display        : flex;
    justify-content: flex-start;
    justify-items  : flex-start;
    align-items    : space-around;
    align-content  : space-around;
    flex-direction : column;
    overflow-y     : auto;
    overflow-x     : hidden;

    .contentDesc {
        width      : 100%;
        height     : auto;
        padding    : 0rem 1rem 1rem 1rem;
        margin-left: 1rem;
        text-align : justify;
    }

    p {
        font-weight: 500;
        font-size  : .9rem;
        width      : 90%;
        height     : auto;
        text-align : justify;
    }
}

.contentFiles {
    width          : 100%;
    justify-content: center;
    justify-items  : center;
    align-items    : flex-start;
    align-content  : flex-start;
    height         : auto;
    flex-direction : column;
    display        : flex;
    cursor         : pointer;

    .rowFile {
        flex-direction : row;
        display        : flex;
        justify-content: center;
        justify-items  : center;
        align-content  : center;
        align-items    : center;
        padding        : 1rem;
        height         : auto;
    }

    .columnFile {
        flex-direction : column;
        display        : flex;
        justify-content: space-around;
        justify-items  : space-around;
        align-content  : flex-start;
        align-items    : flex-start;
        padding        : 1rem 1rem 1rem 0rem;
        height         : auto;
    }

    .iconFile {
        cursor : pointer;
        display: block;
        width  : 2rem;
        height : 2rem;
        @include icon($document, $greyDark, 100%);
        position   : relative;
        z-index    : 2;
        margin-left: 1rem;
        padding    : 1rem;

    }
}

.comments {
    height    : 90%;
    overflow-y: auto;
    overflow-x: hidden;
    display   : block;

    .contentDescRight {
        width         : 90%;
        height        : auto;
        margin-left   : 1rem;
        text-align    : justify;
        padding-top   : 1rem;
        padding-bottom: 1rem;
    }
}


.message {
    display        : flex;
    flex-flow      : column;
    width          : 100%;
    height         : 50%;
    align-items    : center;
    align-content  : center;
    justify-content: flex-start;
    justify-items  : flex-start;
    z-index        : 99;
    margin-top     : 2rem;
    z-index        : 1;

    .text {
        @extend %quicksandRegular;
        color    : $greyDark;
        font-size: 1.2rem;
    }

    .icon {
        width : 5rem;
        height: 5rem;
        @include icon($happy_face, $greyDark, 60%);
    }
}

.iconBox {
    width          : 25%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    justify-items  : flex-start;
    align-content  : flex-start;
    align-items    : flex-start;

    .view {
        width: 10%;
    }

    .iconFavorite {
        cursor : pointer;
        display: block;
        width  : 1.5rem;
        height : 1.5rem;
        @include icon($favorite_outline, $greyDark, 100%);
        position: relative;
        z-index : 2;

        &:hover {
            @include icon($favorite_outline, $danger, 100%);
        }

        &.redLike {
            @include icon($favorite_fill, $danger, 100%);
        }
    }
}