@import './variables.scss';

.leftAside {
    width          : 25%;
    height         : 100%;
    display        : flex;
    justify-content: flex-start;
    justify-items  : flex-start;
    align-items    : center;
    align-content  : center;
    flex-direction : column;
    margin-top     : 2rem;

    ::-webkit-scrollbar {
        display: none;
    }

    .welcome {
        width          : 75%;
        display        : flex;
        flex-direction : column;
        justify-items  : flex-start;
        justify-content: flex-start;
        align-items    : flex-start;
        align-content  : flex-start;

        h2 {
            color: $dark2;
        }

        span {
            color: $dark2;
        }

        .news {
            margin-top: 1rem;
            color     : $dark2;
            @extend %quicksandBold
        }
    }


    .message {
        display        : flex;
        flex-flow      : row;
        width          : 60%;
        height         : 5%;
        align-items    : center;
        align-content  : center;
        justify-content: center;
        justify-items  : center;
        z-index        : 1;
        margin-top     : 2rem;

        .text {
            @extend %quicksandRegular;
            color    : $grey;
            font-size: 1.3rem;
        }

        .icon {
            width : 8rem;
            height: 8rem;
            @include icon($happy_face, $grey, 50%);
        }
    }
}
