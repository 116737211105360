@import './variables.scss';

.list {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  text-decoration: none;
  padding: 0px;
  border: none;
  font-size: 16px;
  //border-radius: 0 30px 30px 0px;
  background: none;
  cursor: pointer;
  position: relative;
  transition: 180ms ease-out;
  &:hover {
    background: rgba(black, .10);
  }
  &.actived {
    span {
      background-color: $blueLight !important;
    }
    p {
      color: $blueLight;
    }
  }
  a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  span {
    width: 24px;
    height: 24px;
    margin: 0px 8px 0 24px;
    &.icon {
      $color: $greyDark;
      &-event {
        @include icon($event, $color, 100%);
      }
      &-tickets {
        @include icon($local_activity, $color, 100%);
      }
      &-website {
        @include icon($language, $color, 100%);
      }
      &-cfp {
        @include icon($how_to_vote, $color, 100%);
      }
      &-speakers {
        @include icon($bussiness, $color, 100%);
      }
      &-attendes {
        @include icon($developer_board, $color, 100%);
      }
      &-sponsors {
        @include icon($work, $color, 100%);
      }
      &-exhibitors {
        @include icon($storefront, $color, 100%);
      }
      &-rooms {
        @include icon($meeting_room, $color, 100%);
      }
      &-tracks {
        @include icon($amp_stories, $color, 100%);
      }
      &-sessions {
        @include icon($list_alt, $color, 100%);
      }
      &-coupon {
        @include icon($local_offer, $color, 100%);
      }
      &-survey {
        @include icon($receipt, $color, 100%);
      }
      &-agenda {
        @include icon($chrome_reader_mode, $color, 100%);
      }
    }
  }
  p {
    color: $greyDark;
    margin-left: 16px;
    font-size: 16px;
  }
}

// .iconDashboard {
//   width : 50%;
//   height: 80%;
//   @include icon($event, $greyDark, 17%);

//   &.hide {
//       @include icon($event, $blue, 17%);
//   }
// }

// .iconTicket {
//   width : 50%;
//   height: 80%;
//   @include icon($local_activity, $greyDark, 17%);

//   &.hide {
//       @include icon($local_activity, $blue, 17%);
//   }
// }

// .iconWebsite {
//   width : 50%;
//   height: 80%;
//   @include icon($language, $greyDark, 17%);

//   &.hide {
//       @include icon($language, $blue, 17%);
//   }
// }

// .iconCallForPaper {
//   width : 50%;
//   height: 80%;
//   @include icon($how_to_vote, $greyDark, 17%);

//   &.hide {
//       @include icon($how_to_vote, $blue, 17%);
//   }
// }

// .iconSpeaker {
//   width : 50%;
//   height: 80%;
//   @include icon($bussiness, $greyDark, 17%);

//   &.hide {
//       @include icon($bussiness, $blue, 17%);
//   }
// }

// .iconAssitant {
//   width : 50%;
//   height: 80%;
//   @include icon($developer_board, $greyDark, 17%);

//   &.hide {
//       @include icon($developer_board, $blue, 17%);
//   }
// }

// .iconSponsor {
//   width : 50%;
//   height: 80%;
//   @include icon($work, $greyDark, 17%);

//   &.hide {
//       @include icon($work, $blue, 17%);
//   }

// }

// .iconMeeting {
//   width : 50%;
//   height: 80%;
//   @include icon($meeting_room, $greyDark, 17%);

//   &.hide {
//       @include icon($meeting_room, $blue, 17%);
//   }
// }


// .iconAmpStories {
//   width : 50%;
//   height: 80%;
//   @include icon($amp_stories, $greyDark, 17%);

//   &.hide {
//       @include icon($amp_stories, $blue, 17%);
//   }
// }

// .iconListAlt {
//   width : 50%;
//   height: 80%;
//   @include icon($list_alt, $greyDark, 17%);

//   &.hide {
//       @include icon($list_alt, $blue, 17%);
//   }
// }

// .iconReceipt {
//   width : 50%;
//   height: 80%;
//   @include icon($receipt, $greyDark, 17%);

//   &.hide {
//       @include icon($receipt, $blue, 17%);
//   }
// }

// .iconChromeReader {
//   width : 50%;
//   height: 80%;
//   @include icon($chrome_reader_mode, $greyDark, 17%);

//   &.hide {
//       @include icon($chrome_reader_mode, $blue, 17%);
//   }
// }

// .iconPromotion {
//   width : 50%;
//   height: 80%;
//   @include icon($local_offer, $greyDark, 17%);

//   &.hide {
//       @include icon($local_offer, $blue, 17%);
//   }

// }
