@import './variables.scss';
 
.postRegister {
  width          : 100%;
  height         : 100%;
  display        : flex;
  justify-content: center;
  justify-items  : center;
  align-items    : center;
  align-content  : center;
}
 
.message {
  display        : flex;
  flex-flow      : column;
  width          : 100%;
  height         : 100vh;
  text-align     : center;
  align-items    : center;
  justify-content: center;
  justify-items  : center;
 
  .text {
    @extend %quicksandRegular;
    color    : $greyDarken;
    font-size: 1.2rem;
  }
 
  .icon {
    width  : 10%;
    height : 10%;
    padding: 7rem;
    @include icon($happy_face, $blueUltraSoft, 100%);
  }
 
  .btn {
    width           : 12rem;
    height          : 2rem;
    color           : $light;
    background-color: $blueLighten;
    text-decoration : none;
    display         : flex;
    align-items     : center;
    justify-content : center;
    font-weight     : 550;
  }
}
 
.backto {
  position: fixed;
  left    : 5rem;
  top     : 4.9rem;
 
  span {
    color: $greyBlack;
  }
 
  .back {
    position: fixed;
    width   : 3rem;
    height  : 3rem;
    color   : $greyBlack;
    top     : 4rem;
    left    : 2.7rem;
    @include icon($chevron_left, $greyBlack, 100%);
  }
}
 
.title {
  position  : absolute;
  top       : 15%;
  text-align: center;
 
  h1 {
    font-weight  : 500;
    color        : $blue;
    margin-bottom: 0.5rem;
  }
 
  h4 {
    font-weight: 200;
    color      : $grey;
  }
}
 
.contentPost {
  cursor  : pointer;
  position: absolute;
  top     : 30%;
 
  .boxtobox {
    display        : flex;
    align-content  : flex-start;
    align-items    : flex-start;
    justify-content: center;
    justify-items  : center;
    flex-direction : row;
 
    .create {
      border-radius  : 2.5%;
      border         : solid 0.1rem $grey;
      width          : 14rem;
      height         : 10rem;
      display        : flex;
      flex-direction : column;
      justify-content: center;
      justify-items  : center;
      align-items    : center;
      align-content  : center;
      margin-right   : 4.5rem;
      color          : $grey;
      font-weight    : 500;
 
      &.blue {
        background-color: $blue;
        color           : $white;
        border          : solid 0.1rem $blue;
 
        .iconPerson {
          @include icon($person, $white, 100%);
        }
 
        &:hover {
          border: solid 0.1rem $blue;
          color : $white;
 
          .iconPerson {
            @include icon($person, $white, 100%);
          }
        }
      }
 
      &:hover {
        border: solid 0.1rem $blue;
        color : $blue;
 
        .iconPerson {
          @include icon($person, $blue, 100%);
        }
      }
 
      .iconPerson {
        position     : relative;
        width        : 5rem;
        height       : 5rem;
        color        : $grey;
        top          : 0.5rem;
        margin-bottom: 0.5rem;
        @include icon($person, $grey, 100%);
      }
    }
 
    .join {
      border-radius  : 2.5%;
      border         : solid 0.1rem $grey;
      width          : 14rem;
      height         : 10rem;
      display        : flex;
      flex-direction : column;
      justify-content: center;
      justify-items  : center;
      align-items    : center;
      align-content  : center;
      margin-left    : 4.5rem;
      color          : $grey;
      font-weight    : 500;
 
      &.blue {
        background-color: $blue;
        color           : $white;
        border          : solid 0.1rem $blue;
 
        .iconPersonGroup {
          @include icon($person_group, $white, 100%);
        }
 
        &:hover {
          border: solid 0.1rem $blue;
          color : $white;
 
          .iconPersonGroup {
            @include icon($person_group, $white, 100%);
          }
        }
      }
 
      &:hover {
        border: solid 0.1rem $blue;
        color : $blue;
 
        .iconPersonGroup {
          @include icon($person_group, $blue, 100%);
        }
      }
 
      .iconPersonGroup {
        position     : relative;
        width        : 5rem;
        height       : 5rem;
        color        : $grey;
        top          : 0.5rem;
        margin-bottom: 0.5rem;
        @include icon($person_group, $grey, 100%);
      }
    }
  }
}
 
.inputGroup {
  margin-top     : 1.5rem;
  display        : flex;
  justify-items  : center;
  justify-content: center;
  align-items    : center;
  align-content  : center;
  flex-direction : column;
 
  input {
    width       : 16rem;
    height      : 2rem;
    border      : solid $grey 0.05rem;
    margin-top  : 0.5rem;
    padding-left: 1rem;
    font-size   : 0.9rem;
    font-weight : 500;
  }
 
  .a {
    text-align   : center;
    justify-items: center;
  }
 
  .button {
    cursor          : pointer;
    justify-content : center;
    align-content   : center;
    text-align      : center;
    align-items     : center;
    font-weight     : 550;
    color           : $white;
    background-color: $blueLighten;
    width           : 12rem;
    line-height     : 1;
    border          : none;
    height          : 2.5rem;
    font-size       : 0.9rem;
    text-align      : center;
    justify-items   : center;
 
    &:focus {
      background-color: $blueDark;
    }
 
    &:disabled {
      background-color: $blueLight;
    }
  }
}