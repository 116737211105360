@import './variables.scss';

.form{
	display: block;
	position: relative;
	width: 100%;
	height: 60%;

	margin-top: 2rem;

	.passError{
		display: none;
		position: absolute;
		top: -2rem;
		width: 100%;
		font-size: .8em;
		color: $danger;

		justify-content: center;

		&.active{
			display: flex;
		}
	}

	.inputGroup{
		display: flex;
		width: 100%;
		height: 27%;
		flex-flow: column;

		border: 1px solid $light3;

		&>div {
			display         : flex;
			flex            : 0 0 50;
			width           : 100%;
			height          : 50%;
			margin          : 0;
			background-color: white;

			&:last-child {
				border-top: 1px solid $light3;
			}

			i {
				display         : block;
				width           : 15%;
				height          : 100%;
				background-color: white;
			}

			&.email>i {
				@include icon($email, $greyLighten, 40%);

				&.active {
					background-color: $blueDark;
				}
			}

			&.password>i {
				@include icon($lock, $greyLighten, 40%);

				&.active {
					background-color: $blueDark;
				}
			}

			input {
				margin: 0;
				width : 85%;
				height: 100%;
				border: none;
			}

			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				color: $grey;
			}

			::-moz-placeholder {
				/* Firefox 19+ */
				color: $grey;
			}

			:-ms-input-placeholder {
				/* IE 10+ */
				color: $grey;
			}

			:-moz-placeholder {
				/* Firefox 18- */
				color: $grey;
			}

		}
	}

	.options {
		display        : flex;
		width          : 100%;
		height         : 2.5rem;
		justify-content: space-between;
		margin-bottom  : 2rem;

		font-size: .7rem;
		color    : $grey;

		div {
			width : 31%;
			height: 100%;
			flex  : 0 0 31%;

			display: flex;

			justify-content: space-between;
			align-items    : center;

			input[type=checkbox] {
				width : 25%;
				height: auto;
				margin: 0;
			}

			&.forgot {
				flex           : 0 0 33%;
				width          : 33%;
				cursor         : pointer;
				justify-content: flex-end;
			}
		}
	}

	.button{
		display: block;
		width: 100%;
		height: 2.5rem;
		border: none;
		font-size: 1.2rem;
		color: white;
		background-color: $blueLight;
		cursor          : pointer;

			&.active{
				background-color: $blueLighten; 
			}
	}

	.forgotModal {
		display   : none;
		position  : fixed;
		top       : 20%;
		left      : 33%;
		border    : 1px solid $light3;
		box-shadow: .2rem .2rem 1rem $light3;

		width           : 35%;
		height          : 56%;
		padding         : 4rem;
		padding-top     : 3rem;
		background-color: white;
		z-index         : 1;

		&.show {
			display: block;
		}

		.closeModal {
			display : block;
			position: absolute;
			right   : .7rem;
			top     : .7rem;

			@include icon($close, $greyLighten, 100%);
			width : 1.5rem;
			height: 1.5rem;
			cursor: pointer;
		}

		.icon {
			width : 100%;
			height: 23%;
			@include icon($email, $blue, contain);

			margin-bottom: .3rem;

			&.sent {
				margin-top: .9rem;
				height    : 40%;
			}
		}

		.text {
			text-align: center;
			@extend %quicksandRegular;
			color    : $greyBlack;
			font-size: .9rem;
		}

		.subt {
			@extend %quicksandRegular;
			text-align   : center;
			color        : $blue;
			font-size    : 1.4rem;
			margin-bottom: 2.3rem;
		}

		.msg {
			@extend %quicksandRegular;
			text-align: center;
			color     : $greyBlack;
			font-size : .9rem;
		}

		.emailForm{

			margin-top: 2rem;
			
			.input{
				@extend %quicksandRegular;
				width: 100%;
				border: 1px solid $light3;
				margin-bottom: 1rem;
				padding: .7rem;
				font-size: 1.1rem
			}

			button {
				background-color: $blueLight;

				&:hover {
					background-color: $blueLighten;
				}
			}

		}
	}

}
