@import './variables.scss';

.card {
    width           : 20%;
    height          : 40%;
    display         : flex;
    flex-direction  : column;
    background-color: $white;
    border-radius   : 5px;
    margin          : 1rem 3rem 1rem 0rem;
    word-wrap       : break-word;
    overflow        : hidden;
    text-overflow   : ellipsis;
    border          : solid $greyDarken3 0.02rem;

    &.list {
        height: 8rem;
        flex-direction: row;
        border-radius: 0.1rem;
        margin: 0rem 1rem 1rem 0rem;
        width: calc(33.333% - 1rem);

        figure{
            max-width: 35%;
        }

        .desc {
            width         : 100%;
            padding-top   : 0.5rem;
            flex-direction: column;

            .date {
                color    : $dark;
                font-size: 0.8rem;

                p {
                    width        : 100%;
                    word-wrap    : break-word;
                    overflow     : hidden;
                    text-overflow: ellipsis;
                    font-size    : 0.9rem;
                    @extend %quicksandRegular;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }

    }

    // color background cards
    &:nth-child(4n-3):not(.inactive){
        figure.hiddenImg{
            background-color: $green;
        }
    }

    &:nth-child(4n-2):not(.inactive){
        figure.hiddenImg{
            background-color: $blueLighten;
        }
    }

    &:nth-child(4n-1):not(.inactive){
        figure.hiddenImg{
            background-color: $yellowCard;
        }
    }

    &:nth-child(4n):not(.inactive){
        figure.hiddenImg{
            background-color: $dark2;
        }
    }

    // clean margin cards
    
    &:nth-child(3n-2):not(.inactive){
        margin-left: 0rem;
    }
    
    &:nth-child(3n):not(.inactive){
        margin-right: 0rem;
    }

    header{

        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 1rem;

        &> h3, &> .title{
            @extend %quicksandBold;
            color: $dark;
            font-size  : 1rem;
            font-weight: 600;
            cursor     : pointer;
            width      : calc(100% - 1.7rem);
            word-wrap    : break-word;
            overflow     : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            &:hover {
                color: $blue;
            }
        }

    }

    figure{
        min-width: 35%;
        height: 100%;
        display: flex;
        object-fit: cover;
        overflow: hidden;

        [icon] {
            display: none;
        }

        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        &.hiddenImg{
            position: relative;
            align-items: center;
            justify-content: center;

            [icon] {
                width: 80px;
                height: 80px;
                background-color: white;
                display: initial;
            }

            img{
                display: none;
            }
        }
    }

    .desc {
        width          : 100%;
        display        : flex;
        color          : $dark;
        font-size      : 0.7rem;
        text-align     : justify;
        justify-content: flex-start;
        align-content  : flex-start;
        justify-items  : flex-start;
        align-items    : flex-start;

        .date {
            color    : $dark;
            font-size: 0.8rem;
            width        : 100%;
            word-wrap    : break-word;
            overflow     : hidden;
            text-overflow: ellipsis;
            font-size    : 0.9rem;
            @extend %quicksandRegular;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        p {
            width        : 100%;
            word-wrap    : break-word;
            overflow     : hidden;
            text-overflow: ellipsis;
            font-size    : 0.9rem;
            @extend %quicksandRegular;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    >section {
        display       : flex;
        flex-direction: column;
        padding: 0.9375rem;
        width: 100%;
    }
}

.cardModal {
    width          : 100%;
    height         : 70%;
    display        : flex;
    flex-direction : column;
    justify-items  : space-between;
    justify-content: space-between;
    align-items    : center;
    align-content  : center;

    h2 {
        @extend %quicksandBold;
    }

    button {
        text-align      : center;
        cursor          : pointer;
        width           : 90%;
        height          : 2rem;
        border          : none;
        color           : $white;
        background-color: $blue;
        font-size       : 1rem;
        @extend %quicksandRegular;
    }

}

.hidden{
    display: none;
}