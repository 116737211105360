@import './variables.scss';

.content {
  z-index        : 99;
  width          : 101%;
  height         : 100%;
  display        : flex;
  flex-wrap      : wrap;
  flex-direction : row;
  justify-content: flex-start;
  justify-items  : self-start;
  align-content  : flex-start;
  align-items    : flex-start;

  input {
    width: 6rem;
  }

  &.none {
    display: none;
  }
}

.contentOne {
  position: relative;
  width   : 100%;
  height  : 100%;

  .oneImage {
    width : 100%;
    height: 100%;
  }
}

.contentPreview {
  display        : flex;
  width          : 140px;
  height         : 140px;
  flex-direction : row;
  justify-content: flex-end;
  justify-items  : flex-end;
  align-content  : flex-start;
  align-items    : flex-start;
  margin-top     : .5rem;
  margin-left    : .3rem;


  &:hover {
    .iconClose {
      @include icon($close, $white, 100%);
    }
  }

  .iconClose {
    cursor: pointer;
    width : 1.2rem;
    height: 1.2rem;
    @include icon($close, transparent, 100%);
    z-index     : 9;
    padding     : .8rem;
    margin-right: -7.8rem;

    &:hover {
      @include icon($close, $greyBlack, 100%);
    }
  }

  .preview {
    width : 100%;
    height: 100%;
  }
}


.contentPreviewFile {
  display        : flex;
  width          : 100%;
  max-height     : 3rem;
  flex-direction : column;
  justify-content: center;
  justify-items  : center;
  align-content  : center;
  align-items    : center;
  padding        : 0.2rem;
  padding-top    : 1rem;
}

.rowFile {
  display        : flex;
  flex-direction : row;
  justify-content: space-around;
  justify-items  : space-around;
  align-content  : space-around;
  align-items    : space-around;
  width          : 100%;
  max-height     : 3rem;

  .previewFile {
    width           : 45%;
    max-height      : 3rem;
    display         : flex;
    flex-direction  : row;
    justify-content : flex-start;
    justify-items   : flex-start;
    align-content   : center;
    align-items     : center;
    background-color: $light3;
    padding         : 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius   : 0.3rem;
    word-wrap       : break-word;
    overflow        : hidden;
    text-overflow   : ellipsis;
  }

  .iconBox {
    width          : 10%;
    max-height     : 3rem;
    justify-content: center;
    justify-items  : center;
    align-content  : center;
    align-items    : center;
    display        : flex;

    .iconCancel {
      cursor: pointer;
      width : 1.5rem;
      height: 1.5rem;
      @include icon($cancel, $greyBlack, 100%);
      z-index : 9;
      display : flex;
      position: relative;

      &:hover {
        @include icon($cancel, $dark, 100%);
      }
    }
  }

  .ready {
    background-color: $success;
    max-height      : 3rem;
    border-radius   : 0.3rem;
    width           : 45%;
    justify-content : center;
    justify-items   : center;
    align-content   : center;
    align-items     : center;
    color           : $white;
    font-weight     : 500;
    display         : flex;
  }
}
