@import './variables.scss';

.wrapperButton {
    
    display         : flex;
    width           : calc(256px - 20px);
    height          : 2rem;
    color           : $greyDark;
    cursor          : pointer;
    flex-flow       : row nowrap; 
    align-items     : center;
    background-color: transparent;
    position        : fixed;
    left            : 20px;
    bottom          : 1rem;

    .circle {
        display: flex;
        width: 3rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: $white;
        border: solid 1px $greyLight3;
        border-radius: 50%;
        z-index: 3;
    }

    .iconFileCopy {
        width: 50%;
        height: 50%;
        @include icon($file_copy, $greyDark, 100%);
    }

    input {
        height: 1.5rem;
        width: 100%;
        position: relative;
        color: $greyDarken2;
        background-color: $greyLight3;
        padding-left: .50rem;
        z-index: 2;
        left: -.25rem;
        border: none;
    }

    &:hover {
        background-color: $greyLight3;
        border-radius: 50px 0 0 50px;
    }

}