@import './variables.scss';

.contentLeft {
    display        : flex;
    flex-direction : row;
    width          : 100%;
    height         : 100%;
    align-content  : center;
    align-items    : center;
    justify-items  : flex-start;
    justify-content: flex-start;

    .image {
        width           : 60%;
        height          : 60%;
        background-color: $greyColor;
        margin-left     : 12.5%;
        display         : flex;
        justify-items   : center;
        justify-content : center;
        align-content   : center;
        align-items     : center;

        .img {
            width : 100%;
            height: 100%;
        }

        &:hover {
            background-color: rgba(#000000, .5);
        }

        .iconUser {
            cursor         : pointer;
            display        : flex;
            justify-items  : center;
            align-content  : center;
            justify-content: center;
            align-items    : center;
            width          : 60%;
            height         : 60%;
            @include icon($account_circle_fill, $greyDark, 100%);
            position: relative;
            z-index : 1;
        }
    }

    .info {
        width         : 35%;
        height        : 60%;
        padding       : 0rem 2rem 2rem 2rem;
        display       : flex;
        flex-direction: column;

        h3 {
            color: $dark;
        }

        span {
            color: $greyBlack;
        }
    }
}

.fileUpload {
    background : $white;
    color      : $blueLighten;
    font-size  : 1em;
    border     : solid 2px $greyDarken3;
    font-weight: bold;
    margin     : 1.25em auto;
    overflow   : hidden;
    padding    : .5rem;
    text-align : center;
    width      : 8rem;
    z-index    : 999;
    cursor     : pointer;
    position   : absolute;
    display    : block;

    span {
        cursor: pointer;
    }
}

.fileUpload:hover {
    background: $greyColor;
    cursor    : pointer;
}

.fileUpload:focus,
.fileUpload:active {
    background: $white;

}


.fileUpload input.upload {
    z-index  : 999;
    position : absolute;
    top      : 0;
    right    : 0;
    margin   : 0;
    padding  : 0;
    font-size: 20px;
    cursor   : pointer;
    opacity  : 0;
    filter   : alpha(opacity=0);
    width    : 148px;
    height   : 46px;
    cursor   : pointer;
}

input[type="file"] {
    position: fixed;
    right   : 100%;
    bottom  : 100%;
    z-index : 999;

}

.custom-file-upload {
    z-index: 999;
    border : solid 2px $greyDarken3;
    display: inline-block;
    padding: 6px 12px;
    cursor : pointer;
}
