@import './variables.scss';

.background {
    position        : fixed;
    width           : 101%;
    height          : 100%;
    background-color: $shadow;
    display         : flex;
    flex-direction  : row;
    justify-content : center;
    align-items     : center;
    justify-items   : center;
    align-content   : center;
    z-index         : 7;
    margin-top      : -5rem;
}

.row {
    position: fixed;
    top     : 14%;
    left    : 89%;
    z-index : 8;

    .iconClose {
        cursor : pointer;
        display: block;
        width  : 2rem;
        height : 2rem;
        @include icon($close, $white, 100%);
        position: relative;
        z-index : 9;

        &:hover {
            @include icon($close, $blueLight, 100%);
        }
    }
}

.box {
    margin-bottom   : 0rem;
    width           : 75%;
    height          : 65%;
    background-color: $white;
}
