@import './variables.scss';
 
.container {
    width           : 100%;
    height          : calc(100% - 7%);
    background-color: $light;
    display         : flex;
    overflow        : hidden;
 
    .example::-webkit-scrollbar {
        display: block;
    }
 
    .example {
        -ms-overflow-style: block;
    }
}
 
.contentModule{
    width: 82%;
}