@import './variables.scss';

.createEvent {
	color   : $dark;
	position: relative;
	width   : 100%;
	height  : 93%;
	overflow: auto;

	background-color: $light;

	display   : block;
	text-align: center;

	.back {
		position: fixed;
		width   : 3rem;
		height  : 3rem;

		top : 4rem;
		left: 2rem;

		@include icon($chevron_left, $greyBlack, 100%)
	}

	h2 {
		@extend %quicksandBold;
		color        : $dark;
		font-size    : 2rem;
		margin-top   : 4rem;
		margin-bottom: 1rem;
	}

	.center {
		width     : 35%;
		text-align: center;

		display: inline-block;

		&>button {
			width : 10rem;
			height: 2rem;
			margin: 2rem 0 4rem 0;

			border          : none;
			color           : $white;
			background-color: $blueLight;
		}
	}

	.eventForm {

		width  : 100%;
		height : auto;
		padding: 3rem;

		background-color: $white;
		box-shadow      : 0 0 .5rem $grey;
		text-align      : left;

		display  : flex;
		flex-flow: column nowrap;

		&>div {

			margin: 1rem 0;

			h3 {
				@extend %quicksandMedium;
				font-size    : 1.5rem;
				color        : $blue;
				margin-bottom: .5rem;
			}

			label {
				@extend %quicksandRegular;
				display  : block;
				font-size: .8rem;
				color    : $dark;
				width    : 100%;
			}

			input {
				border       : 1px solid $grey;
				margin-top   : 0px;
				height       : 2rem;
				margin-bottom: .3rem;
				width: 90%;

				&.url {
					width: 60%;
				}
			}

			span {
				@extend %quicksandRegular;
				font-size: 1.2rem;

				&.url {
					display   : inline-block;
					width     : 40%;
					margin    : 0;
					text-align: center;
				}
			}

			.options {
				display      : flex;
				width        : 100%;
				height       : 5rem;
				margin-bottom: 2rem;

				justify-content: space-around;

				
				.face {
					position: relative;
					width: 3rem;
					height: 3rem;
					color: $grey;
					margin-left: 32%;
					@include icon($group_out, $grey, 100%);
					&.blue{
						@include icon($group_out, $white, 100%);
				  }
				  }
				  .online {
					position: relative;
					width: 3rem;
					height:3rem;
					margin-left: 32%;
					color: $grey;
					@include icon($language, $grey, 100%);
					&.blue{
						@include icon($language, $white, 100%);
				  }
				}
				span {
					@extend %quicksandMedium;
					font-size: .8rem;
				}

				button {
					flex-flow       : 0 0 30%;
					width           : 30%;
					height          : 100%;
					background-color: $white;
					border          : 1px solid $greyDark;
					cursor          : pointer;

					display: inline-block;

					&.active {
						background-color: $blue;
						color           : $white;
						border          : 1px solid $white;
					}
				}
			}

			.date {
				display: inline-block;
				width  : 50%;
				div{
					width              : 100%;

				}
				input {
					background-image   : url($event);
					background-size    : 14%;
					background-position: center left;
					background-repeat  : no-repeat;
					width              : 90%;
					padding-left       : 2rem;


					&:hourValidation {
						border: 4px solid $redCard;
						color : $redCard,
					}
				}
			}

			.time {
				display: inline-block;
				width  : 50%;

				input {
					background-image   : url($schedule);
					background-size    : 14%;
					background-position: center left;
					background-repeat  : no-repeat;
					width              : 90%;
					padding-left       : 2rem;
				}
			}
		}

		.draft {
			display: flex;
			width  : 100%;

			flex-flow: row nowrap;

			&>* {
				display: block;
			}

			input[type='checkbox'] {
				flex            : 0 0 5%;
				width           : 5%;
				background-color: $white;
				border          : 1px solid $grey;
			}

			label {
				flex       : 0 0 90%;
				width      : 90%;
				display    : flex;
				align-items: center;
			}
		}
	}

	.inputAddress {
		width          : 100%;
		height         : 1.2rem;
		display        : flex;
		justify-content: flex-start;
		align-content  : flex-start;
		position       : relative;

		input {
			width       : 100%;
			padding-left: 0.4rem;
		}

		&.hide {
			display: none;
		}
	}
}