@import './variables.scss';

.attend {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: column;
    transition: 200ms;
    padding-left: 32px;
    margin-bottom: 16px;
    &.hidden {
        display: none;
    }
    header {
        width: 100%;
        min-height: 36px;
        display: flex;
        margin-top: 16px;
        .info {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            //justify-content: center;

            figure{
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 16px;
                display: flex;
                object-fit: cover;
                overflow: hidden;
        
                [icon] {
                    display: none;
                }
        
                img{
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
        
                &.hiddenImg{
                    position: relative;
                    align-items: center;
                    justify-content: center;
        
                    [icon] {
                        width: 80px;
                        height: 80px;
                        background-color: $greyDark;
                        display: initial;
                    }
        
                    img{
                        display: none;
                    }
                }
            }
            
            h6 {
                font-size: 18px;
                font-weight: 500;
            }
        }
        p {
            width: 100%;
            height: 100%;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .actions {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                width: 180px;
                background: $green;
            }
        }
    }
    .moreInfo {
        width: 100%;
        flex-grow: 1;
        margin: 16px 0;
        position: relative;
        overflow: hidden;
        display: flex;
        p {
            font-size: 16px;
            font-weight: 500;
            margin-right: 24px;
            color: rgba(black, .80);
            span {
                display: block;
                margin-bottom: 8px;
            }
        }
    }
    &.expander {
        background: rgba(black, .05);
        height: 200px;
    }
}