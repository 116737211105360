@import './variables.scss';

.boxCenter {
  border-radius  : 0.1rem;
  width          : 95%;
  height         : auto;
  border         : solid 0.01rem $light3;
  z-index        : 3;
  flex-direction : column;
  background     : $white;
  display        : flex;
  align-items    : center;
  align-content  : center;
  justify-content: space-around;
  justify-items  : space-around;
  padding-bottom : 0.5rem;
  padding-top    : 1rem;


  .left {
    width          : 49%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    justify-items  : flex-start;
    align-items    : center;
    align-content  : center;
  }

  .right {
    width          : 48%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
    justify-items  : flex-end;
    align-items    : center;
    align-content  : center;
  }


  .content {
    display        : flex;
    justify-content: center;
    justify-items  : center;
    align-content  : center;
    align-items    : center;
    flex-direction : column;
    width          : 96%;
    height         : auto;


    textarea {
      resize          : none;
      border          : solid 0.5px $light3;
      border-radius   : 0.1rem;
      width           : 100%;
      text-decoration : none;
      height          : auto;
      min-height      : 4rem;
      font-size       : 1.3rem;
      padding-left    : 1rem;
      padding-top     : 0.5rem;
      color           : $dark2;
      background-color: $light2;
      display         : flex;
      justify-items   : center;
      justify-content : center;
      align-items     : center;
      align-content   : center;
      overflow        : hidden;

      &:focus {
        color           : $dark;
        background-color: $white;
      }
    }
  }

  .footer {
    display        : flex;
    justify-content: center;
    justify-items  : center;
    align-content  : center;
    align-items    : center;
    width          : 99%;
    flex-direction : row;
    height         : 3rem;
    padding-top    : .5rem;

    button {
      cursor          : pointer;
      display         : flex;
      justify-content : center;
      justify-items   : center;
      align-content   : center;
      align-items     : center;
      width           : 20%;
      background-color: $blue;
      border          : none;
      height          : 2rem;
      border-radius   : 0.2rem;
      font-size       : 1rem;
      color           : $white;
      @extend %quicksandMedium;

      @include breakpoint(hdscreenonly) {
        font-size: 0.7rem;
      }
    }

    .iconImage {
      cursor : pointer;
      display: block;
      width  : 2rem;
      height : 2rem;
      @include icon($image_outline, $greyDark, 100%);
      position: relative;
      z-index : 2;
      overflow: hidden;
      padding : 1rem;

      &:hover {
        @include icon($image_outline, $blue, 100%);
      }

      input[type=file] {
        cursor : pointer;
        opacity: 0;
        height : 100%;
      }
    }

    .iconVideo {
      cursor : pointer;
      display: block;
      width  : 2rem;
      height : 2rem;
      @include icon($slideshow, $greyDark, 100%);
      position   : relative;
      z-index    : 2;
      margin-left: 1rem;
      padding    : 1rem;

      &:hover {
        @include icon($slideshow, $blue, 100%);
      }

      input[type=file] {
        cursor : pointer;
        opacity: 0;
        height : 100%;
      }
    }

    .iconFile {
      cursor : pointer;
      display: block;
      width  : 2rem;
      height : 2rem;
      @include icon($file, $greyDark, 100%);
      position   : relative;
      z-index    : 2;
      margin-left: 1rem;
      padding    : 1rem;

      &:hover {
        @include icon($file, $blue, 100%);
      }

      input[type=file] {
        cursor : pointer;
        opacity: 0;
        height : 100%;
      }
    }
  }
}

.modalPublic {
  width          : 100%;
  height         : 70%;
  display        : flex;
  flex-direction : column;
  justify-items  : space-between;
  justify-content: space-between;
  align-items    : center;
  align-content  : center;
  @extend %quicksandMedium;

  @include breakpoint(mobileonly) {

    height: auto;
  }

  @include breakpoint(tabletonly) {
    height: auto;
  }

  h2 {
    @extend %quicksandBold;
  }

  button {
    text-align      : center;
    cursor          : pointer;
    width           : 50%;
    height          : 2.2rem;
    border          : none;
    color           : $white;
    background-color: $blue;
    font-size       : 1.2rem;
    @extend %quicksandBold;

    @include breakpoint(mobileonly) {
      font-size: .7rem;
      height: auto;
    }

    @include breakpoint(tabletonly) {
      font-size: .9rem;
      height: auto;
    }
  }
}